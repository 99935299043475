import React from 'react';
import { LoaderWrapper, LoadingSpinner, SpinnerContainer } from './styles';
import { LoaderProps } from './types';

const Loader = ({ size = 'lg', loaderStyle, loaderType = 'page' }: LoaderProps) => {
  return (
    <>
      {loaderType === 'page' ? (
        <LoaderWrapper>
          <SpinnerContainer>
            {loaderStyle ? loaderStyle : <LoadingSpinner $size={size} />}
          </SpinnerContainer>
        </LoaderWrapper>
      ) : (
        <LoadingSpinner $size={size} />
      )}
    </>
  );
};

export default Loader;
