const CreateClient = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 15H16.25V12.5H15V15H12.5V16.25H15V18.75H16.25V16.25H18.75V15Z"
        fill="#012023"
      />
      <path
        d="M9.625 18.75L3.125 14.875C2.75 14.625 2.5 14.25 2.5 13.8125V6.1875C2.5 5.75 2.75 5.3125 3.125 5.125L9.375 1.4375C9.5625 1.3125 9.75 1.25 10 1.25C10.25 1.25 10.4375 1.3125 10.625 1.4375L16.875 5.125C17.25 5.375 17.5 5.75 17.5 6.1875V10H16.25V6.1875L10 2.5L3.75 6.1875V13.8125L10.3125 17.6875L9.625 18.75Z"
        fill="#012023"
      />
    </svg>
  );
};

export default CreateClient;
