import { Line } from '@visx/shape';
import { Group } from '@visx/group';
import { useTheme } from 'styled-components';

import { ScatterPlotVizProps } from './ScatterPlot.types';
import { PointLabelWrapper } from './ScatterPlot.styles';

const getColorMix = (
  value: number,
  minValue: number,
  maxValue: number,
  colorMin: string,
  colorMax: string,
) => {
  let percentage = ((value - minValue) / (maxValue - minValue)) * 100;

  percentage = Math.min(Math.max(percentage, 0), 100); // Ensures percentage is between 0 and 100

  return `color-mix(in srgb, ${colorMin}, ${colorMax} ${percentage}%)`;
};

const getFillColor = (value: number) => {
  const colors = ['#FF8C47', '#FF4781', '#473DD9', '#36C4A5'];
  const stops = [0.25, 0.42, 0.58, 0.75];

  const referenceValue = value + 2; // Adjusted to always be positive
  const totalSegment = 4; // Total range from -2 to +2

  for (let i = 0; i < stops.length; i++) {
    if (referenceValue <= totalSegment * stops[i]) {
      return i === 0
        ? colors[0]
        : getColorMix(
            referenceValue,
            totalSegment * stops[i - 1],
            totalSegment * stops[i],
            colors[i - 1],
            colors[i],
          );
    }
  }
  return colors[colors.length - 1];
};

export const ScatterPlotViz = ({
  data,
  getX,
  xScale,
  yScale,
  dataKeysY,
  handleMouseMove,
  handleMouseLeave,
  colorScale,
  gridProps,
}: ScatterPlotVizProps) => {
  const theme = useTheme();

  return dataKeysY.map((dataKey, index) => (
    <Group key={`point-type-groups-${dataKey}-${index}`}>
      {data.map(
        (d, i) =>
          d[dataKey] && (
            <Group
              key={`point-group-${dataKey}-${index}-${i}`}
              {...(handleMouseMove
                ? {
                    onMouseMove: (event) => {
                      handleMouseMove(event, {
                        color: colorScale(yScale(d[dataKey] as number)),
                        data: {
                          value: d[dataKey],
                        },
                      });
                    },
                    onTouchMove: (event) => {
                      handleMouseMove(event, {
                        color: colorScale(yScale(d[dataKey] as number)),
                        data: {
                          value: d[dataKey],
                        },
                      });
                    },
                  }
                : {})}
              {...(handleMouseLeave
                ? {
                    onMouseLeave: handleMouseLeave,
                    onTouchEnd: handleMouseLeave,
                  }
                : {})}
            >
              {d['entity_id'] === d['highlighted_entity_id'] && (
                <>
                  <circle
                    cx={xScale(getX(d))}
                    cy={yScale(d[dataKey] as number)}
                    r="6.5"
                    fill="transparent"
                    fillOpacity="0"
                    stroke={theme.neutralBackgroundExtraDark}
                    strokeWidth="1.5"
                  />

                  <Line
                    from={{ x: xScale(getX(d)), y: yScale(d[dataKey] as number) + 6 }}
                    to={{
                      x: xScale(getX(d)),
                      y: yScale(d[dataKey] as number) + 40 + (55 - yScale(d[dataKey] as number)),
                    }}
                    stroke={theme['neutralBackgroundExtraDark']}
                    strokeWidth={1}
                    pointerEvents="none"
                    strokeDasharray="1,1"
                  />

                  <foreignObject
                    x={xScale(getX(d))}
                    y={yScale(d[dataKey] as number)}
                    width="1"
                    height="1"
                    style={{
                      position: 'relative',
                      overflow: 'visible',
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                  >
                    <PointLabelWrapper
                      $x={xScale(getX(d)) as number}
                      $y={yScale(d[dataKey] as number)}
                      $gridWidth={gridProps.width}
                      $gridHeight={gridProps.height}
                    >
                      Your Property
                    </PointLabelWrapper>
                  </foreignObject>
                </>
              )}

              <circle
                cx={xScale(getX(d))}
                cy={yScale(d[dataKey] as number)}
                r="4"
                fill="transparent"
                fillOpacity="0"
              />

              <circle
                cx={xScale(getX(d))}
                cy={yScale(d[dataKey] as number)}
                r="3.5"
                fill={getFillColor(d[dataKey] as number)}
                stroke={theme.neutralBorderWeakest}
                strokeWidth="0.5"
                fillOpacity="0.9"
              />
            </Group>
          ),
      )}
    </Group>
  ));
};
