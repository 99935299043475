const MainNeighbor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="#FF4781"
        fillRule="evenodd"
        d="M.445 6.92 6.921.446a1.53 1.53 0 0 1 2.158 0l6.476 6.476a1.53 1.53 0 0 1 0 2.158l-6.476 6.476a1.53 1.53 0 0 1-2.158 0L.445 9.079a1.53 1.53 0 0 1 0-2.158Zm6.56-3.154a1 1 0 1 1 1.99 0l-.388 6.351a.61.61 0 0 1-1.215 0l-.387-6.35Zm.995 7.9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default MainNeighbor;
