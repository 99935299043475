import { XAction } from '@assets/icons/XAction';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

interface Props {
  title: string;
  modalOpen: boolean;
  closeModal: () => void;
  Icon: () => JSX.Element;
  children: React.ReactNode;
}

export const ModalLayout = (props: Props): JSX.Element => {
  const { title, modalOpen, closeModal, Icon, children } = props;

  const ref = useRef(null);

  useOnClickOutside(ref, closeModal);

  return modalOpen ? (
    <div className="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-modalGradient">
      <div
        ref={ref}
        className="flex flex-col gap-6 rounded-xl bg-light-neutralBackgroundBase shadow-modalShadowFirst mx-2.5 w-full h-max md:w-Fixed p-10"
      >
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <Icon />
            <h2 className="text-2xl font-semibold">{title}</h2>
          </div>
          <button
            onClick={closeModal}
            className="flex gap-1.5 text-xs font-medium text-light-neutralTextWeak items-center h-max"
          >
            <span>Close</span>
            <XAction />
          </button>
        </div>
        {children}
      </div>
    </div>
  ) : (
    ('' as unknown as JSX.Element)
  );
};
