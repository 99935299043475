export const industryChains: Record<string, string[]> = {
  'Dollar Stores': ['Dollar General', 'Family Dollar', 'Dollar Tree', 'Five Below'],
  Pharmacies: ['Walgreens', 'CVS', 'Rite Aid'],
  'Big Box': ['Costco Wholesale', 'Walmart', 'Target', "BJ's Wholesale Club", "Sam's Club", 'Big Lots'],
  Automotive: [
    'AutoZone',
    "O'Reilly Auto Parts",
    'Advance Auto Parts',
    'Valvoline Instant Oil Change',
    'Jiffy Lube',
    'Take 5 Oil Change',
    'Pep Boys Auto Parts & Service',
    'Mavis Discount Tire',
    'Caliber Collision',
  ],
  Banks: ['Bank of America', 'Chase Bank', 'Wells Fargo', 'PNC Bank', 'Truist'],
  Wireless: ['T-Mobile', 'Verizon', 'AT&T'],
  Fitness: ['LA Fitness'],
};
