type Props = {
  onClick?: () => void;
};

const Eyes = ({ onClick }: Props) => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M15.4698 7.83146C14.8817 6.31029 13.8608 4.99477 12.5332 4.04751C11.2056 3.10024 9.62953 2.56275 7.99979 2.50146C6.37005 2.56275 4.79398 3.10024 3.46639 4.04751C2.1388 4.99477 1.11787 6.31029 0.529787 7.83146C0.490071 7.94132 0.490071 8.06161 0.529787 8.17146C1.11787 9.69264 2.1388 11.0082 3.46639 11.9554C4.79398 12.9027 6.37005 13.4402 7.99979 13.5015C9.62953 13.4402 11.2056 12.9027 12.5332 11.9554C13.8608 11.0082 14.8817 9.69264 15.4698 8.17146C15.5095 8.06161 15.5095 7.94132 15.4698 7.83146ZM7.99979 12.5015C5.34979 12.5015 2.54979 10.5365 1.53479 8.00146C2.54979 5.46646 5.34979 3.50146 7.99979 3.50146C10.6498 3.50146 13.4498 5.46646 14.4648 8.00146C13.4498 10.5365 10.6498 12.5015 7.99979 12.5015Z"
        fill="#02484F"
      />
      <path
        d="M7.99979 5.00146C7.40644 5.00146 6.82642 5.17741 6.33308 5.50706C5.83973 5.8367 5.45521 6.30524 5.22815 6.85341C5.00109 7.40159 4.94168 8.00479 5.05743 8.58674C5.17319 9.16868 5.45891 9.70323 5.87847 10.1228C6.29802 10.5423 6.83257 10.8281 7.41452 10.9438C7.99646 11.0596 8.59966 11.0002 9.14784 10.7731C9.69602 10.546 10.1646 10.1615 10.4942 9.66818C10.8238 9.17483 10.9998 8.59481 10.9998 8.00146C10.9998 7.20582 10.6837 6.44275 10.1211 5.88014C9.5585 5.31754 8.79544 5.00146 7.99979 5.00146ZM7.99979 10.0015C7.60422 10.0015 7.21755 9.88417 6.88865 9.6644C6.55975 9.44464 6.3034 9.13228 6.15203 8.76683C6.00065 8.40138 5.96105 7.99925 6.03822 7.61128C6.11539 7.22332 6.30587 6.86696 6.58557 6.58725C6.86528 6.30755 7.22164 6.11706 7.60961 6.03989C7.99757 5.96272 8.3997 6.00233 8.76515 6.15371C9.13061 6.30508 9.44296 6.56143 9.66273 6.89032C9.88249 7.21922 9.99979 7.6059 9.99979 8.00146C9.99979 8.5319 9.78907 9.04061 9.414 9.41568C9.03893 9.79075 8.53022 10.0015 7.99979 10.0015Z"
        fill="#02484F"
      />
    </svg>
  );
};

export default Eyes;
