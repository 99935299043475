import OpenArrow from '@assets/icons/svgIcons/openArrow.svg';

type OpenButtonProps = {
  onClick: () => void;
};

export const OpenButton = ({ onClick }: OpenButtonProps) => {
  return (
    <div
      className="text-[#02484F] font-bold text-[12px] flex items-center select-none gap-[5px] cursor-pointer "
      onClick={onClick}
    >
      <p>Open</p>
      <img src={OpenArrow} alt="" className="bottom-[-1px]" />
    </div>
  );
};
