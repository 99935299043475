import { DetailBlockProps } from './types';

const DetailBlock = ({ title, description }: DetailBlockProps) => {
  return (
    <div className="px-4 py-3 rounded-xl bg-light-neutralBackgroundBase">
      <p className="text-base font-semibold leading-none text-light-neutralText">{title}</p>

      <span className="text-xs font-normal leading-none text-light-neutralTextWeak">
        {description}
      </span>
    </div>
  );
};

export default DetailBlock;
