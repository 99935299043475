import { ArrowRight } from '@assets/icons/ArrowRight';
import { AutoCompleteSelect } from '@components/AutoCompleteSelect';
import { industryChains } from '../constants/industryChains';
import { useCallback, useEffect, useState } from 'react';
import { AxiosController } from '@utils/axiosController';
import Loader from '@components/Loader';
import {toast} from "react-toastify";

interface Step2Props {
  values: Record<string, any>;
  errors: Record<string, any>;
  touched: Record<string, any>;
  handleChange: (e: any) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  setValues: (values: any) => void;
  clientId?: string;
}

export const Step2 = ({
  values,
  errors,
  touched,
  handleChange,
  handleNextStep,
  handlePreviousStep,
  setValues,
  clientId,
}: Step2Props) => {
  const [addressOptions, setAddressOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { get, post } = AxiosController();

  const getAddresses = useCallback(async () => {
    const addressResultsLimit = 1000;

    const result = await get(
      `/properties?limit=${addressResultsLimit}&chain=${encodeURIComponent(values.brandProprety)}&address=${values.addressFilter || ''}`,
    );

    const options = result?.items
      ?.map((el: any) => {
        return { value: el.entity_id, label: el.address };
      })
      .reverse();

    setAddressOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, values.brandProprety]);

  const handleConfirmNextStep = async () => {
    if (!values.existing && values.name && values.email && values.company) {
      setLoading(true);
      const fileResult = await Promise.resolve(post('/files/upload', values.files))
        .then((d) => d)
        .catch((err) => {
          setLoading(false);
          return console.error('Error uploading file: ', err);
        });
      const clientData = {
        fullName: values.name,
        email: values.email,
        logo: fileResult?.items?.[0]?.path,
        company: {
          name: values.company,
          logo: fileResult?.items?.[1]?.path || fileResult?.items?.[0]?.path,
        },
      };
      const clientResult = await Promise.resolve(post('/clients', clientData))
        .then((d) => d)
        .catch((err) => {
          setLoading(false);
          return console.error('Error creating client: ', err);
        });
      const reportData = {
        clientId: clientResult?._id,
        propertyId: values.address,
      };
      const reportResult = await Promise.resolve(post('/reports', reportData))
        .then((d) => d)
        .catch((err) => {
          setLoading(false);
          return console.error('Error creating report: ', err);
        });
      setValues({ ...values, reportID: reportResult?._id });
      setLoading(false);
      handleNextStep();
    } else if ((clientId || values.existing) && !values.name && !values.email && !values.company) {
      try {
        setLoading(true);
        const reportData = {
          clientId: values?.existing || clientId,
          propertyId: values.address,
        };
        const reportResult = await post('/reports', reportData);
        setValues({ ...values, reportID: reportResult?._id });

        setLoading(false);
        handleNextStep();
      } catch (error) {
        return console.error('error creating report from current client: ', error);
      }
    } else {
      toast("Please fill in all required fields")
      setLoading(false);
    }

  };

  useEffect(() => {
    setValues({ ...values, brandProprety: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.industry]);

  useEffect(() => {
    setValues({ ...values, address: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.brandProprety]);

  useEffect(() => {
    if (values.addressFilter) {
      getAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.addressFilter]);

  return loading ? (
    <Loader size="lg" />
  ) : (
    <>
      <h3 className="text-xl">What property?</h3>
      <div className="flex flex-col gap-4">
        <AutoCompleteSelect
          id={'industry'}
          placeholder={'Industry'}
          values={values.industry}
          errors={errors.industry}
          touched={touched.industry}
          options={
            Object.keys(industryChains).map((el) => ({ value: el, label: el })) || [
              { value: 'test', label: 'test' },
            ]
          }
          handleChange={handleChange}
          filterSensitivity={0}
        />
        <AutoCompleteSelect
          id={'brandProprety'}
          placeholder={!values.industry ? 'Select industry to fill brand' : 'Brand'}
          disabled={!values.industry}
          values={values.brandProprety}
          errors={errors.brandProprety}
          touched={touched.brandProprety}
          options={
            industryChains?.[values?.industry]?.map((el: string) => ({ value: el, label: el })) || [
              { value: 'brand1', label: 'Select industry' },
            ]
          }
          handleChange={handleChange}
          filterSensitivity={0}
        />
        <AutoCompleteSelect
          id={'address'}
          placeholder={
            !values.industry || !values.brandProprety
              ? `Select${values.industry ? '' : ' industry'}${!values.industry && !values.brandProprety ? ' and' : ''} ${values.brandProprety ? '' : 'brand'} to fill address`
              : 'Address'
          }
          disabled={!(values.industry || values.brandProprety)}
          values={values.address}
          errors={errors.address}
          touched={touched.address}
          options={addressOptions}
          setValues={setValues}
          handleChange={handleChange}
          debounce={300}
        />
      </div>
      <span className="w-full border-t border-dark-neutralBorder"></span>
      <div className="flex justify-between">
        {!clientId && (
          <button
            onClick={handlePreviousStep}
            type="button"
            className="flex items-center gap-2 px-3 py-2 text-sm font-semibold rounded-lg text-light-primaryButton-default w-max text-nowrap"
          >
            <ArrowRight rotate />
            Back
          </button>
        )}
        <button
          onClick={handleConfirmNextStep}
          type="button"
          className="flex gap-2 ml-auto items-center bg-[#0E717B14] py-2 px-3 text-sm text-light-primaryButton-default font-semibold rounded-lg w-max text-nowrap"
        >
          Request Report
          <ArrowRight />
        </button>
      </div>
    </>
  );
};
