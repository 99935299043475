import React from 'react';

import { CoreChartBase } from '../CoreChartBase';
import { ScatterPlotViz } from './ScatterPlotViz';

import { ScatterPlotProps } from './ScatterPlot.types';
import { ScattterPlotWrapperStyled } from './ScatterPlot.styles';

export const ScatterPlot = (props: ScatterPlotProps) => {
  return (
    <ScattterPlotWrapperStyled>
      <CoreChartBase {...{ ...props, xScaleType: 'linear', withTooltip: true }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ScatterPlotViz />
      </CoreChartBase>
    </ScattterPlotWrapperStyled>
  );
};
