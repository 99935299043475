const MaximizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#111"
        d="M10.333 1.333v1h3.293L9.333 6.624l.707.71 4.293-4.294v3.293h1v-5h-5ZM7.333 10.041l-.704-.708-4.296 4.293v-3.293h-1v5h5v-1H3.04l4.293-4.292Z"
      />
    </svg>
  );
};

export default MaximizeIcon;
