import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { SortConfig, TableProps } from './types';
import EmptyTable from '@assets/icons/EmptyTable';
import Loader from '@components/Loader';
import { SubItemArrow } from '@assets/icons/SubItemArrow';

const Table = <Data extends Record<string, any>>({
  data,
  columns,
  initialSortField,
  initialSortDirection = 'ASC',
  sortColumn,
  loading,
}: TableProps<Data>) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<Data>>({
    key: initialSortField,
    direction: initialSortDirection,
  });

  const sortedData = useMemo(() => {
    if (!sortConfig) return [...data];

    const sortingKey = sortConfig.key;
    const sortingDirection = sortConfig.direction === 'ASC' ? 1 : -1;

    return [...data].sort((a, b) => {
      const getValue = (item: any) =>
        Array.isArray(item) ? item[0][sortingKey] : item[sortingKey];

      const aValue = getValue(a);
      const bValue = getValue(b);

      return aValue < bValue ? -sortingDirection : aValue > bValue ? sortingDirection : 0;
    });
  }, [data, sortConfig]);

  const requestSort = useCallback(
    (key: keyof Data) => {
      let direction: 'ASC' | 'DESC' = 'ASC';
      const sortCondition = sortConfig && sortConfig.key === key && sortConfig.direction === 'ASC';

      if (sortCondition) {
        direction = 'DESC';
      }
      setSortConfig({ key, direction });
    },
    [sortConfig],
  );

  useEffect(() => {
    if (sortColumn && sortColumn.sortColumnKey) {
      requestSort(sortColumn.sortColumnKey);
      sortColumn.sortColumHandler(sortConfig.direction);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn]);

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col">
        <table className="min-w-full">
          <thead>
            <tr className="w-full text-sm font-normal text-light-neutralText">
              {columns.map(({ label, accessor, sortable, widthClass }) => (
                <th
                  key={String(accessor)}
                  onClick={() => sortable && requestSort(accessor)}
                  className={`relative z-10 px-4 py-3 text-sm font-normal text-left flex-1 ${sortable ? 'cursor-pointer' : ''} ${widthClass ? widthClass : ''}`}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!loading && !!sortedData.length ? (
              sortedData.map((item, index) => {
                const isArray = Array.isArray(item);

                return isArray ? (
                  item.map((row, rowIndex) => (
                    <React.Fragment key={`item-${index}-row-${rowIndex}`}>
                      {!rowIndex && (
                        <tr key={`item-${index}-placeholder-row`}>
                          <td colSpan={columns.length} className="p-[1px]"></td>
                        </tr>
                      )}
                      <tr
                        key={`item-${index}-row-${rowIndex}-data`}
                        className={`text-sm bg-light-neutralBackgroundBase text-light-neutralText`}
                      >
                        {columns.map(({ accessor }, colIndex) => (
                          <td
                            key={`item-${index}-row-${rowIndex}-col-${colIndex}`}
                            className={`relative z-10 px-4 py-3 text-left ${!rowIndex && '[&:first-of-type]:rounded-tl-lg [&:last-of-type]:rounded-tr-lg'} ${item.length === rowIndex + 1 ? '[&:first-of-type]:rounded-bl-lg [&:last-of-type]:rounded-br-lg' : ''}`}
                          >
                            {!colIndex && !!rowIndex ? (
                              <div className="flex items-center gap-2">
                                <SubItemArrow />
                                <span className="text-light-neutralTextWeak text-[12px]">
                                  {row[accessor]}
                                </span>
                              </div>
                            ) : (
                              row[accessor]
                            )}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment key={`item-${index}-data`}>
                    <tr key={`item-${index}-placeholder-row`}>
                      <td colSpan={columns.length} className="p-[1px]"></td>
                    </tr>
                    <tr
                      key={`item-${index}-actual-row`}
                      className="my-1 text-sm bg-light-neutralBackgroundBase text-light-neutralText "
                    >
                      {columns.map(({ accessor }, colIndex) => (
                        <td
                          key={`item-${index}-col-${colIndex}`}
                          className="px-4 py-3 my-1 text-left [&:first-of-type]:rounded-l-lg [&:last-of-type]:rounded-r-lg relative z-10 "
                        >
                          {item[accessor]}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr className="bg-white h-[44px] rounded-lg overflow-hidden">
                <td colSpan={columns.length} className="px-4 py-[6px]">
                  <div className="w-full border border-dashed border-[#6DD1FB] text-[#004766] font-bold text-[12px] rounded flex justify-center items-center py-2 gap-2">
                    {loading ? (
                      <Loader loaderType="component" size="md" />
                    ) : (
                      <>
                        <EmptyTable />
                        <p>There are no records to display for the selected date range</p>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
