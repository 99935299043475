import { formatDate } from '@utils/formatData';
import { OpenButton } from '../components/OpenButton';
import { ColumnConfig } from '@components/Table/types';
import { UserData } from '../types/types';

const mockUserData: UserData[] = [
  {
    _id: '1',
    company: { id: '1', name: 'Company A' },
    createdAt: '2022-01-01T00:00:00Z',
    email: 'john.doe@example.com',
    fullName: 'John Doe',
    updatedAt: '2022-01-02T00:00:00Z',
    companyName: 'Company A',
    action: {
      /* example action */
    },
  },
  {
    _id: '2',
    company: { id: '2', name: 'Company B' },
    createdAt: '2022-01-02T00:00:00Z',
    email: 'jane.smith@example.com',
    fullName: 'Jane Smith',
    updatedAt: '2022-01-03T00:00:00Z',
    companyName: 'Company B',
    action: {
      /* example action */
    },
  },
  {
    _id: '3',
    company: { id: '3', name: 'Company C' },
    createdAt: '2022-01-03T00:00:00Z',
    email: 'alice.johnson@example.com',
    fullName: 'Alice Johnson',
    updatedAt: '2022-01-04T00:00:00Z',
    companyName: 'Company C',
    action: {
      /* example action */
    },
  },
  {
    _id: '4',
    company: { id: '4', name: 'Company D' },
    createdAt: '2022-01-04T00:00:00Z',
    email: 'bob.williams@example.com',
    fullName: 'Bob Williams',
    updatedAt: '2022-01-05T00:00:00Z',
    companyName: 'Company D',
    action: {
      /* example action */
    },
  },
  {
    _id: '5',
    company: { id: '5', name: 'Company E' },
    createdAt: '2022-01-05T00:00:00Z',
    email: 'emma.brown@example.com',
    fullName: 'Emma Brown',
    updatedAt: '2022-01-06T00:00:00Z',
    companyName: 'Company E',
    action: {
      /* example action */
    },
  },
  {
    _id: '6',
    company: { id: '6', name: 'Company F' },
    createdAt: '2022-01-06T00:00:00Z',
    email: 'william.jones@example.com',
    fullName: 'William Jones',
    updatedAt: '2022-01-07T00:00:00Z',
    companyName: 'Company F',
    action: {
      /* example action */
    },
  },
];

export const columns: ColumnConfig<UserData>[] = [
  { label: 'Client Name', accessor: 'fullName', sortable: false, widthClass: 'w-1/5' },
  { label: 'Email', accessor: 'email', sortable: false },
  { label: 'Company', accessor: 'companyName', sortable: false },
  { label: 'Client Creation Date', accessor: 'createdAt', sortable: false, widthClass: 'w-1/5' },
  { label: 'Action', accessor: 'action', sortable: false, widthClass: 'w-1/8' },
];

export const processedData = (data: UserData[], navigate: (url: string) => void) =>
  data.map((item) => ({
    ...item,
    createdAt: formatDate(item.createdAt),
    companyName: item.company.name,
    action: <OpenButton onClick={() => navigate(`/clients/${item._id}`)} />,
  }));
