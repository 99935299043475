const env = {
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  keicloackClientId: process.env.REACT_APP_SSO_CLIENT_ID,
  keicloackUrl: process.env.REACT_APP_SSO_URL,
  keicloackRealm: process.env.REACT_APP_SSO_REALM,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.env = env;

export default env;
