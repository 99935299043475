import React, { memo } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import useFeatures from '@services/hooks/session/useFeatures';

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <h1 className="text-3xl text-error">404</h1>
        <h2 className="text-lg">
          <span className="text-link">{location.pathname}</span> Not Found
        </h2>
        <button className="text-lg text-link mt-8" onClick={() => navigate('/')}>
          Go to home page
        </button>
      </div>
    </>
  );
};

export default memo(NotFound);
