import { PropsWithChildren } from 'react';

import { CustomButton, IconWrapper } from './styles';
import { ButtonProps } from './types';

const Button = ({
  onClick,
  icon,
  iconLeft,
  variant = 'primary',
  type = 'button',
  size = 'lg',
  disabled,
  iconOnly = false,
  className,
  children,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <CustomButton
      $variant={variant}
      $size={size}
      type={type}
      onClick={onClick}
      $disabled={disabled}
      disabled={disabled}
      $iconOnly={iconOnly}
      className={className}
    >
      <IconWrapper $small={size === 'sm'} $variant={variant}>
        {!iconOnly ? iconLeft : null}
        {!iconOnly ? children : null}
        {icon}
      </IconWrapper>
    </CustomButton>
  );
};

export default Button;
