import Table from '@components/Table';
import Badge from '@components/Badge';
import { Card } from '@components/Card';
import { SectionTitle } from '@components/SectionTitle';
import { getBadgeStatus } from '@components/Badge/status';

import FeaturesIcon from '@assets/icons/Features';
import { BadgeStatus } from '@constants/badge';
import Info from '@assets/icons/info';
import ComparisonCard from '@components/ComparisonCard';
import YourPropertyIcon from '@assets/icons/YourProperty';
import MainNeighbor from '@assets/icons/MainNeighbor';

import { FormatedDataItem, MainComparisonProps, PropertyData } from './MainComparison.types';
import { replaceUSwithZipcode } from '@utils/replaceUSwithZipcode';

export const MainComparison = ({
  comparisonKeys,
  propertyData,
  mainNeighborData,
  features,
}: MainComparisonProps) => {
  const columns = [
    {
      sortable: true,
      label: 'Feature Name',
      accessor: 'featureName',
      widthClass: 'w-1/3',
    },
    { sortable: true, label: 'Value', accessor: 'value', widthClass: 'w-1/6' },
    {
      sortable: false,
      label: 'Valence',
      accessor: 'valence',
      widthClass: 'w-1/6',
    },
    {
      sortable: true,
      label: 'Value',
      accessor: 'value_competitor',
      widthClass: 'w-1/6',
    },
    {
      sortable: false,
      label: 'Valence',
      accessor: 'valence_competitor',
      widthClass: 'w-1/6',
    },
  ];

  const getFeature = (featureName: string) => {
    const feature = features.filter((item) => item.Features === featureName)[0];

    return feature;
  };

  const getUnit = (featureName: string) => {
    const feature = getFeature(featureName);
    const featureUnit = feature?.Units;

    return featureUnit;
  };

  const formatData = (
    comparisonKeys: string[],
    propertyData: PropertyData,
    mainNeighborData: PropertyData,
  ) => {
    const formattedData: FormatedDataItem[] = comparisonKeys.map((key: string) => {
      const featureName = key;
      const featureUnit = getUnit(featureName as string);

      const propertyValue =
        featureName === 'U.S. State'
          ? propertyData['U']['S'][' state']
          : `${propertyData[featureName]}${featureUnit ? ' ' + featureUnit : ''}`;
      const propertyValence =
        key === 'U.S. State'
          ? propertyData['U']['S'][' State_valence']
          : propertyData[`${featureName}_valence`];

      const mainNeighborValue =
        featureName === 'U.S. State' && !!mainNeighborData['U']
          ? mainNeighborData['U']['S'][' state']
          : `${mainNeighborData[featureName] ?? 'No Data Set '} ${featureUnit ? ' ' + featureUnit : ''}`;
      const mainNeighborValence =
        key === 'U.S. State' && !!mainNeighborData['U']
          ? mainNeighborData['U']['S'][' State_valence']
          : mainNeighborData[`${featureName}_valence`] ?? 'No Data Set';

      const rowData = {
        featureName: featureName,
        value: propertyValue,
        valence: (
          <Badge
            label={propertyValence as string}
            muted
            size="sm"
            variant={getBadgeStatus(propertyValence as BadgeStatus)}
            icon={<Info />}
          />
        ),
        value_competitor: mainNeighborValue,
        valence_competitor: (
          <Badge
            label={mainNeighborValence as string}
            muted
            size="sm"
            variant={getBadgeStatus(mainNeighborValence as BadgeStatus)}
            icon={<Info />}
          />
        ),
      };

      return rowData;
    });

    return formattedData;
  };

  return (
    <section className="flex flex-col gap-4">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <SectionTitle title="Main Neighbor Comparison" icon={<FeaturesIcon />} />

          <p className="px-[30px] mt-2 text-base leading-5 text-light-neutralTextWeak">
            We compare your property to the property belonging to the same chain that competes most
            directly with it.
          </p>
        </div>

        <div className="relative z-10 before:-z-10 before:block before:absolute before:-inset-1 before:rounded-t-[20px] before:border before:-mb-[80%] before:bg-gradient-to-b before:from-sky-100 before:border-light-dataViz-qualitative_12-10 before:border-b-0 before:gradient-mask-b-50 before:mix-blend-multiply before:select-none">
          <ComparisonCard
            {...{
              title: propertyData['chain'] as string,
              address: replaceUSwithZipcode(
                propertyData['address'],
                propertyData['zipcode'],
              ) as string,
              barLabel1: 'National',
              barLabel2: propertyData['state'] as string,
              percentage1: propertyData['Percentile Rank National'] as number,
              percentage2: propertyData['Percentile Rank State'] as number,
              primary: 'primary',
              accentColor: 'bg-light-dataViz-qualitative_12-10',
              icon: <YourPropertyIcon />,
            }}
          />
        </div>

        <div>
          <ComparisonCard
            {...{
              title: mainNeighborData['chain'] as string,
              address: replaceUSwithZipcode(
                mainNeighborData['address'],
                mainNeighborData['zipcode'],
              ) as string,
              barLabel1: 'National',
              barLabel2: mainNeighborData['state'] as string,
              percentage1: mainNeighborData['Percentile Rank National'] as number,
              percentage2: mainNeighborData['Percentile Rank State'] as number,
              primary: 'secondary',
              accentColor: 'bg-light-dataViz-qualitative_12-8',
              icon: <MainNeighbor />,
            }}
          />
        </div>
      </div>

      <Card>
        <Table
          data={formatData(comparisonKeys, propertyData, mainNeighborData)}
          columns={columns as any}
          initialSortField={'featureName'}
        />
      </Card>
    </section>
  );
};
