const YourPropertyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <rect width="16" height="16" fill="#47B0FF" rx="2" />
      <path
        fill="#fff"
        d="M8 1 5.725 5.61.64 6.345l3.68 3.59L3.45 15 8 12.61 12.55 15l-.87-5.065 3.68-3.585-5.085-.74L8 1Z"
      />
    </svg>
  );
};

export default YourPropertyIcon;
