import * as Tokens from '../tokens/tokens';

const theme = {
  /*----Text Colors----*/
  //Neutral
  neutralText: Tokens.LightNeutralTextDefault,
  neutralTextWeak: Tokens.LightNeutralTextWeak,
  neutralTextWeaker: Tokens.LightNeutralTextWeaker,
  neutralTextWeakest: Tokens.LightNeutralTextWeakest,
  neutralTextInverted: Tokens.LightNeutralTextInverted,
  neutralTextInvertedWeak: Tokens.LightNeutralTextInvertedWeak,
  neutralTextInvertedWeaker: Tokens.LightNeutralTextInvertedWeaker,
  neutralTextInvertedWeakest: Tokens.LightNeutralTextInvertedWeakest,

  //Primary
  primaryText: Tokens.LightPrimaryTextDefault,
  primaryTextWeak: Tokens.LightPrimaryTextWeak,
  primaryTextWeaker: Tokens.LightPrimaryTextWeaker,
  primaryTextWeakest: Tokens.LightPrimaryTextWeakest,
  primaryTextInverted: Tokens.LightPrimaryTextInverted,
  primaryTextInvertedWeak: Tokens.LightPrimaryTextInvertedWeak,
  primaryTextInvertedWeakest: Tokens.LightPrimaryTextInvertedWeakest,

  //Secondary
  secondaryText: Tokens.LightSecondaryTextDefault,
  secondaryTextWeak: Tokens.LightSecondaryTextWeak,

  //System/Info
  infoText: Tokens.LightSystemInfoTextDefault,
  infoTextWeak: Tokens.LightSystemInfoTextInverted,

  //System/Success
  successText: Tokens.LightSystemSuccessTextDefault,
  successTextWeak: Tokens.LightSystemSuccessTextInverted,

  //System/Warning
  warningText: Tokens.LightSystemWarningTextDefault,
  warningTextWeak: Tokens.LightSystemWarningTextInverted,

  //System/Error
  errorText: Tokens.LightSystemErrorTextDefault,
  errorTextWeak: Tokens.LightSystemErrorTextInverted,

  /*--------------------------------Icon Colors--------------------------------*/

  //Neutral
  neutralIcon: Tokens.LightNeutralIconDefault,
  neutralIconWeak: Tokens.LightNeutralIconWeak,
  neutralIconWeaker: Tokens.LightNeutralIconWeaker,
  neutralIconWeakest: Tokens.LightNeutralIconWeakest,
  neutralIconInverted: Tokens.LightNeutralIconInverted,
  neutralIconInvertedWeak: Tokens.LightNeutralIconInvertedWeak,
  neutralIconInvertedWeaker: Tokens.LightNeutralIconInvertedWeaker,
  neutralIconInvertedWeakest: Tokens.LightNeutralIconInvertedWeakest,

  //Primary
  primaryIcon: Tokens.LightPrimaryIconDefault,
  primaryIconWeak: Tokens.LightPrimaryIconWeak,
  primaryIconWeaker: Tokens.LightPrimaryIconWeaker,
  primaryIconWeakest: Tokens.LightPrimaryIconWeakest,
  primaryIconInverted: Tokens.LightPrimaryIconInverted,
  primaryIconInvertedWeak: Tokens.LightPrimaryIconInvertedWeak,
  primaryIconInvertedWeakest: Tokens.LightPrimaryIconInvertedWeakest,

  //Secondary
  secondaryIcon: Tokens.LightSecondaryIconDefault,
  secondaryIconWeak: Tokens.LightSecondaryIconWeak,

  //System/Info
  infoIcon: Tokens.LightSystemInfoIconDefault,
  infoIconWeak: Tokens.LightSystemInfoIconWeak,
  infoIconInverted: Tokens.LightSystemInfoIconInverted,

  //System/Success
  successIcon: Tokens.LightSystemSuccessIconDefault,
  successIconWeak: Tokens.LightSystemSuccessIconWeak,
  successIconInverted: Tokens.LightSystemSuccessIconInverted,

  //System/Warning
  warningIcon: Tokens.LightSystemWarningIconDefault,
  warningIconWeak: Tokens.LightSystemWarningIconWeak,
  warningIconInverted: Tokens.LightSystemWarningIconInverted,

  //System/Error
  errorIcon: Tokens.LightSystemErrorIconDefault,
  errorIconWeak: Tokens.LightSystemErrorIconWeak,
  errorInverted: Tokens.LightSystemErrorIconInverted,

  /*--------------------------------Border Colors--------------------------------*/

  //Neutral
  neutralBorder: Tokens.LightNeutralBorderDefault,
  neutralBorderWeakest: Tokens.LightNeutralBorderWeakest,
  neutralBorderWeakestHover: Tokens.LightNeutralBorderWeakestHover,
  neutralBorderWeakestActive: Tokens.LightNeutralBorderWeakestActive,

  //Primary
  primaryBorder: Tokens.LightPrimaryBorderDefault,
  primaryBorderHover: Tokens.LightPrimaryBorderHover,
  primaryBorderActive: Tokens.LightPrimaryBorderActive,
  primaryBorderDisabled: Tokens.LightPrimaryBorderDisabled,

  //Secondary
  secondaryBorder: Tokens.LightSecondaryBorderDefault,

  //System
  infoBorder: Tokens.LightSystemInfoBorderDefault,
  successBorder: Tokens.LightSystemSuccessBorderDefault,
  warningBorder: Tokens.LightSystemWarningBorderDefault,
  errorBorder: Tokens.LightSystemErrorBorderDefault,

  /*--------------------------------Background Colors--------------------------------*/

  //Neutral
  neutralBackground: Tokens.LightNeutralBackgroundDefault,
  neutralBackgroundBase: Tokens.LightNeutralBackgroundBase,
  neutralBackgroundBaseInverted: Tokens.LightNeutralBackgroundBaseInverted,
  neutralBackgroundStrongest: Tokens.LightNeutralBackgroundStrongest,
  neutralBackgroundStronger: Tokens.LightNeutralBackgroundStronger,
  neutralBackgroundStrong: Tokens.LightNeutralBackgroundStrong,
  neutralBackgroundHover: Tokens.LightNeutralBackgroundHover,
  neutralBackgroundActive: Tokens.LightNeutralBackgroundActive,
  neutralBackgroundExtraDark: Tokens.LightNeutralBackgroundExtraDark,

  //Primary
  primaryBackground: Tokens.LightPrimaryBackgroundDefault,
  primaryBackgroundStrongest: Tokens.LightPrimaryBackgroundStrongest,
  primaryBackgroundStronger: Tokens.LightPrimaryBackgroundStronger,
  primaryBackgroundStrong: Tokens.LightPrimaryBackgroundStrong,
  primaryBackgroundHover: Tokens.LightPrimaryBackgroundHover,
  primaryBackgroundActive: Tokens.LightPrimaryBackgroundActive,

  //Secondary
  secondaryBackground: Tokens.LightSecondaryBackgroundDefault,
  secondaryBackgroundStrongest: Tokens.LightSecondaryBackgroundStrongest,

  //System/Info
  infoBackground: Tokens.LightSystemInfoBackgroundDefault,
  infoBackgroundHover: Tokens.LightSystemInfoBackgroundHover,
  infoBackgroundActive: Tokens.LightSystemInfoBackgroundActive,
  infoBackgroundWeakest: Tokens.LightSystemInfoBackgroundWeakest,
  infoBackgroundWeakestHover: Tokens.LightSystemInfoBackgroundWeakestHover,
  infoBackgroundWeakestActive: Tokens.LightSystemInfoBackgroundWeakestActive,
  infoBackgroundTransparent: Tokens.LightSystemInfoBackgroundTransparent,

  //System/Success
  successBackground: Tokens.LightSystemSuccessBackgroundDefault,
  successBackgroundHover: Tokens.LightSystemSuccessBackgroundHover,
  successBackgroundActive: Tokens.LightSystemSuccessBackgroundActive,
  successBackgroundWeakest: Tokens.LightSystemSuccessBackgroundWeakest,
  successBackgroundWeakestHover: Tokens.LightSystemSuccessBackgroundWeakestHover,
  successBackgroundWeakestActive: Tokens.LightSystemSuccessBackgroundWeakestActive,
  successBackgroundTransparent: Tokens.LightSystemSuccessBackgroundTransparent,

  //System/Warning
  warningBackground: Tokens.LightSystemWarningBackgroundDefault,
  warningBackgroundHover: Tokens.LightSystemWarningBackgroundHover,
  warningBackgroundActive: Tokens.LightSystemWarningBackgroundActive,
  warningBackgroundWeakest: Tokens.LightSystemWarningBackgroundWeakest,
  warningBackgroundWeakestHover: Tokens.LightSystemWarningBackgroundWeakestHover,
  warningBackgroundWeakestActive: Tokens.LightSystemWarningBackgroundWeakestActive,
  warningBackgroundTransparent: Tokens.LightSystemWarningBackgroundTransparent,

  //System/Error
  errorBackground: Tokens.LightSystemErrorBackgroundDefault,
  errorBackgroundHover: Tokens.LightSystemErrorBackgroundHover,
  errorBackgroundActive: Tokens.LightSystemErrorBackgroundActive,
  errorBackgroundWeakest: Tokens.LightSystemErrorBackgroundWeakest,
  errorBackgroundWeakestHover: Tokens.LightSystemErrorBackgroundWeakestHover,
  errorBackgroundWeakestActive: Tokens.LightSystemErrorBackgroundWeakestActive,
  errorBackgroundTransparent: Tokens.LightSystemErrorBackgroundTransparent,

  //Fill
  warningFill: Tokens.LightSystemWarningFill,

  /*--------------------------------Data Visualization--------------------------------*/
  dataViz: {
    sequential_7_1: [
      Tokens.LighterSequentialColors71Hue1,
      Tokens.LighterSequentialColors71Hue2,
      Tokens.LighterSequentialColors71Hue3,
      Tokens.LighterSequentialColors71Hue4,
      Tokens.LighterSequentialColors71Hue5,
      Tokens.LighterSequentialColors71Hue6,
      Tokens.LighterSequentialColors71Hue7,
    ],
    qualitative_2_1: [Tokens.LighterQualitativeColors21Hue1, Tokens.LighterQualitativeColors21Hue2],
    qualitative_2_2: [Tokens.LighterQualitativeColors22Hue1, Tokens.LighterQualitativeColors22Hue2],
    qualitative_4: [
      Tokens.LighterQualitativeColors4Hue1,
      Tokens.LighterQualitativeColors4Hue2,
      Tokens.LighterQualitativeColors4Hue3,
      Tokens.LighterQualitativeColors4Hue4,
    ],
    qualitative_3_cfs_score: [
      Tokens.LighterQualitativeColors3CfsScoreHue1,
      Tokens.LighterQualitativeColors3CfsScoreHue2,
      Tokens.LighterQualitativeColors3CfsScoreHue3,
    ],
    qualitative_3_cfs_score_2: [
      Tokens.LighterQualitativeColors3CfsScore2Hue1,
      Tokens.LighterQualitativeColors3CfsScore2Hue2,
      Tokens.LighterQualitativeColors3CfsScore2Hue3,
    ],
    qualitative_12: [
      Tokens.LighterQualitativeColors12Hue1,
      Tokens.LighterQualitativeColors12Hue2,
      Tokens.LighterQualitativeColors12Hue3,
      Tokens.LighterQualitativeColors12Hue4,
      Tokens.LighterQualitativeColors12Hue5,
      Tokens.LighterQualitativeColors12Hue6,
      Tokens.LighterQualitativeColors12Hue7,
      Tokens.LighterQualitativeColors12Hue8,
      Tokens.LighterQualitativeColors12Hue9,
      Tokens.LighterQualitativeColors12Hue10,
      Tokens.LighterQualitativeColors12Hue11,
      Tokens.LighterQualitativeColors12Hue12,
    ],
  },

  /*--------------------------------Button--------------------------------*/
  textPrimaryButton: Tokens.ButtonTextPrimary,
  textSecondaryButton: Tokens.ButtonTextSecondary,
  textSecondaryGrayButton: Tokens.ButtonTextSecondaryGray,
  textTertiaryButton: Tokens.ButtonTextTertiary,
  textTertiaryDisabledButton: Tokens.ButtonTextTertiaryDisabled,

  borderDefaultButton: Tokens.ButtonBorderDefault,
  borderTertiaryButton: Tokens.ButtonBorderTertiary,

  greenInputActive: Tokens.InputActive,

  primaryButton: {
    default: Tokens.ButtonBackgroundColorPrimaryDefault,
    hover: Tokens.ButtonBackgroundColorPrimaryHover,
    active: Tokens.ButtonBackgroundColorPrimaryActive,
    focus: Tokens.ButtonBackgroundColorPrimaryFocus,
    disabled: Tokens.ButtonBackgroundColorPrimaryDisabled,
    empty: Tokens.ButtonBackgroundColorPrimaryEmpty,
  },
  primaryGrayButton: {
    default: Tokens.ButtonBackgroundColorPrimaryGrayDefault,
    hover: Tokens.ButtonBackgroundColorPrimaryGrayHover,
    active: Tokens.ButtonBackgroundColorPrimaryGrayActive,
    focus: Tokens.ButtonBackgroundColorPrimaryGrayFocus,
    empty: Tokens.ButtonBackgroundColorPrimaryGrayEmpty,
    disabled: Tokens.ButtonBackgroundColorPrimaryGrayDisabled,
  },
  secondaryButton: {
    default: Tokens.ButtonBackgroundColorSecondaryDefault,
    hover: Tokens.ButtonBackgroundColorSecondaryHover,
    active: Tokens.ButtonBackgroundColorSecondaryActive,
    focus: Tokens.ButtonBackgroundColorSecondaryFocus,
    empty: Tokens.ButtonBackgroundColorSecondaryEmpty,
    disabled: Tokens.ButtonBackgroundColorSecondaryDisabled,
  },
  secondaryGrayButton: {
    default: Tokens.ButtonBackgroundColorSecondaryGrayDefault,
    hover: Tokens.ButtonBackgroundColorSecondaryGrayHover,
    active: Tokens.ButtonBackgroundColorSecondaryGrayActive,
    focus: Tokens.ButtonBackgroundColorSecondaryGrayFocus,
    empty: Tokens.ButtonBackgroundColorSecondaryGrayEmpty,
    disabled: Tokens.ButtonBackgroundColorSecondaryGrayDisabled,
  },
  tertiaryButton: {
    default: Tokens.ButtonBackgroundColorTertiaryDefault,
    hover: Tokens.ButtonBackgroundColorTertiaryHover,
    active: Tokens.ButtonBackgroundColorTertiaryActive,
    focus: Tokens.ButtonBackgroundColorTertiaryFocus,
    empty: Tokens.ButtonBackgroundColorTertiaryEmpty,
    disabled: Tokens.ButtonBackgroundColorTertiaryDisabled,
  },
  ghostButton: {
    default: Tokens.ButtonBackgroundColorGhostDefault,
    hover: Tokens.ButtonBackgroundColorGhostHover,
    active: Tokens.ButtonBackgroundColorGhostActive,
    focus: Tokens.ButtonBackgroundColorGhostFocus,
    empty: Tokens.ButtonBackgroundColorGhostEmpty,
    disabled: Tokens.ButtonBackgroundColorGhostDisabled,
  },
  errorButton: {
    default: Tokens.ButtonBackgroundColorErrorDefault,
    hover: Tokens.ButtonBackgroundColorErrorHover,
    active: Tokens.ButtonBackgroundColorErrorActive,
    focus: Tokens.ButtonBackgroundColorErrorFocus,
  },

  /*--------------------------------Badge--------------------------------*/
  badge: {
    default: Tokens.BadgeInteractiveBackgroundColorDafault,
    hover: Tokens.BadgeInteractiveBackgroundColorHover,
    pressed: Tokens.BadgeInteractiveBackgroundColorPressed,
    selected: Tokens.BadgeInteractiveBackgroundColorSelected,
    disabled: Tokens.BadgeInteractiveBackgroundColorDisabled,
    empty: Tokens.BadgeInteractiveBackgroundColorEmpty,
    focus: Tokens.BadgeInteractiveBackgroundColorFocus,
  },

  badgeText: Tokens.BadgeInteractiveTextDefault,
  badgeSelectedText: Tokens.BadgeInteractiveTextSelected,
  statusBadgeText: {
    default: Tokens.BadgeStatusTextDefault,
    inverted: Tokens.BadgeStatusTextInverted,
    error: Tokens.BadgeStatusTextError,
    success: Tokens.BadgeStatusTextSuccess,
    warning: Tokens.BadgeStatusTextWarning,
    info: Tokens.BadgeStatusTextInfo,
  },
  statusBadgeBackgroundSaturated: {
    default: Tokens.BadgeStatusBackgroundColorDafaultSaturated,
    error: Tokens.BadgeStatusBackgroundColorErrorSaturated,
    success: Tokens.BadgeStatusBackgroundColorSuccessSaturated,
    warning: Tokens.BadgeStatusBackgroundColorWarningSaturated,
    info: Tokens.BadgeStatusBackgroundColorInfoSaturated,
  },
  statusBadgeBackgroundMuted: {
    default: Tokens.BadgeStatusBackgroundColorDafaultMuted,
    error: Tokens.BadgeStatusBackgroundColorErrorMuted,
    success: Tokens.BadgeStatusBackgroundColorSuccessMuted,
    warning: Tokens.BadgeStatusBackgroundColorWarningMuted,
    info: Tokens.BadgeStatusBackgroundColorInfoMuted,
  },

  /*--------------------------------Link--------------------------------*/
  link: {
    default: Tokens.LinkBackgroundColorDefault,
    hover: Tokens.LinkBackgroundColorHover,
    pressed: Tokens.LinkBackgroundColorPressed,
    selected: Tokens.LinkBackgroundColorSelected,
    focus: Tokens.LinkBackgroundColorFocus,
    error: Tokens.LinkBackgroundColorError,
    empty: Tokens.LinkBackgroundColorEmpty,
  },
  linkText: {
    default: Tokens.LinkTextDefault,
    hover: Tokens.LinkTextHover,
    pressed: Tokens.LinkTextPressed,
    selected: Tokens.LinkTextSelected,
    focus: Tokens.LinkTextFocus,
    error: Tokens.LinkTextError,
  },

  /*--------------------------------Avatar--------------------------------*/
  avatarBorder: {
    default: Tokens.AvatarBorderDefault,
    hover: Tokens.AvatarBorderHover,
    active: Tokens.AvatarBorderActive,
  },
  avatarBackground: {
    default: Tokens.AvatarBackgroundColorDefault,
    empty: Tokens.AvatarBackgroundColorEmpty,
  },
  /*--------------------------------Loader--------------------------------*/

  loaderBackground: Tokens.LoaderBackground,
  loaderAccent: Tokens.LoaderAccent,
};

export default theme;
