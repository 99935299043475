import { useEffect, useState } from 'react';

import { Card } from '@components/Card/Card';
import { LineChart } from '@components/Charts/LineChart';

import { DataProps, StoreClosingsCardProps } from './StoreClosingsCard.types';

const valueFormatter = (value: number): string =>
  `${Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value)}%`;

const dataKeysMap = {
  '1-Year Closure Rate': {
    key: 'Horizon',
    keyValue: 'Last year',
    valueType: 'State',
  },
  '2-Year Closure Rate': {
    key: 'Horizon',
    keyValue: 'Last 2 years',
    valueType: 'State',
  },
  '3-Year Closure Rate': {
    key: 'Horizon',
    keyValue: 'Last 3 years',
    valueType: 'State',
  },
  '4-Year Closure Rate': {
    key: 'Horizon',
    keyValue: 'Last 4 years',
    valueType: 'State',
  },
  '5-Year Closure Rate': {
    key: 'Horizon',
    keyValue: 'Last 5 years',
    valueType: 'State',
  },
  '1-Year Closure Rate (National)': {
    key: 'Horizon',
    keyValue: 'Last year',
    valueType: 'National',
  },
  '2-Year Closure Rate (National)': {
    key: 'Horizon',
    keyValue: 'Last 2 years',
    valueType: 'National',
  },
  '3-Year Closure Rate (National)': {
    key: 'Horizon',
    keyValue: 'Last 3 years',
    valueType: 'National',
  },
  '4-Year Closure Rate (National)': {
    key: 'Horizon',
    keyValue: 'Last 4 years',
    valueType: 'National',
  },
  '5-Year Closure Rate (National)': {
    key: 'Horizon',
    keyValue: 'Last 5 years',
    valueType: 'National',
  },
};

export const StoreClosingsCard = ({ data }: StoreClosingsCardProps) => {
  const [chartData, setChartData] = useState<DataProps[]>([]);
  const state = data['state' as keyof typeof data] as any as string;

  useEffect(() => {
    const chartDataObj: any = {};

    Object.keys(data).forEach((key: any) => {
      if (key === 'state') return;

      const itemKey = dataKeysMap[key as keyof typeof dataKeysMap];
      const itemKeyValue = itemKey.keyValue as keyof typeof chartDataObj;

      if (!chartDataObj[itemKeyValue]) {
        chartDataObj[itemKeyValue] = {
          Horizon: itemKeyValue,
        };
      }

      if (itemKey.valueType === 'National') {
        chartDataObj[itemKeyValue].National = data[key];
      }

      if (itemKey.valueType === 'State') {
        chartDataObj[itemKeyValue].State = data[key];
      }
    });

    const newChartData = Object.values(chartDataObj) as unknown as DataProps[];

    setChartData(newChartData);
  }, [data, state]);

  return (
    <Card className="flex flex-col gap-4">
      <h3 className="text-2xl leading-none tracking-tight">Chain Store Closings</h3>

      {chartData.length ? (
        <div className="min-h-[248px] p-6 pr-8 rounded-2xl bg-white overflow-hidden animate-fadein [animation-duration:0.4s]">
          <LineChart
            data={chartData}
            dataKeys={{
              x: 'Horizon',
              y: ['National', 'State'],
            }}
            height={200}
            colorPalette={'qualitative_2_1'}
            leftAxisLabel={'% of total'}
            leftAxisTickFormatter={valueFormatter}
            legendPosition="top"
            maxYScaleValueOffsetFactor={1.5}
            niceScale={true}
            pointLabelFormatter={valueFormatter}
            roundScale={true}
            showBottomAxis={true}
            showLeftAxis={true}
            showVerticalAxesTicks={true}
            withPointsLabels={true}
            yAxisNumTicks={4}
          />
        </div>
      ) : null}
    </Card>
  );
};
