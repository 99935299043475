import { useState, useRef, useCallback, useMemo } from 'react';
import { useDebounceCallback, useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';

import { SelectChevronDown } from '@assets/icons/SelectChevronDown';

type SelectOption = {
  value: string;
  label: string;
};

interface AutoCompleteSelectProps {
  id: string;
  options: SelectOption[];
  handleChange: (e: React.MouseEvent<HTMLOptionElement, MouseEvent>) => void;
  values?: string;
  placeholder?: string;
  errors?: string;
  touched?: boolean;
  required?: boolean;
  disabled?: boolean;
  setValues?: (values: any) => void;
  debounce?: number;
  filterSensitivity?: number;
}

export const AutoCompleteSelect = ({
  id,
  options,
  handleChange,
  values,
  placeholder,
  disabled,
  setValues,
  debounce = 0,
  filterSensitivity = 3,
}: AutoCompleteSelectProps) => {
  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const debounced = useDebounceCallback(setValues ? setValues : () => {}, debounce);

  const ref = useRef<HTMLDivElement>(null);

  const filteredOptions = useMemo(() => {
    if (!filterValue || setValues || filterValue?.length < filterSensitivity) return options;

    return options.filter((option) =>
      option.label.toLowerCase().includes(filterValue?.toLowerCase()),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, options]);

  const handleToggle = useCallback(() => {
    if (disabled === true) {
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  }, [disabled]);

  const handleInputClick = useCallback(
    (e: any) => {
      if (!filterValue || !open || disabled === true) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [filterValue, open, disabled],
  );

  const handleSelectOption = useCallback(
    (e: React.MouseEvent<HTMLOptionElement, MouseEvent>) => {
      setFilterValue('');

      handleChange(e);
      setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange],
  );

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled === true) {
      return;
    }
    setFilterValue(e.target.value);

    if (setValues && e.target.value.length >= 3) {
      debounced((prev: any) => ({ ...prev, addressFilter: e.target.value }));
    }

    setOpen(true);
  };

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} className="relative w-full h-full">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center w-full gap-2 px-3 text-sm border rounded bg-light-neutralBackgroundBase text-ui-gray11 border-ui-gray4"
      >
        <input
          value={filterValue}
          onChange={handleFilterChange}
          onClick={handleInputClick}
          disabled={disabled}
          placeholder={
            filteredOptions.find((el) => el.value === values)?.label ||
            values ||
            placeholder ||
            'Select an option'
          }
          className={classNames(
            'flex bg-light-neutralBackgroundBase mr-auto outline-none py-3 w-full placeholder-light-primaryGrayButton-default',
            { 'cursor-pointer': !filterValue, 'select-none': disabled },
          )}
        />
        <span className="h-5 mr-1 border-r border-ui-gray4" />
        <SelectChevronDown open={open} />
      </button>
      {open && (
        <div className="absolute left-0 z-50 w-full h-full pt-2 top-full">
          <div className="flex flex-col w-full max-h-[200px] overflow-auto bg-light-neutralBackgroundBase border border-ui-gray4 rounded shadow-modalShadowSecond">
            {filterValue?.length >= filterSensitivity && filteredOptions ? (
              filteredOptions.map((option, index) => (
                <option
                  key={option.value}
                  id={id}
                  value={option.value}
                  onClick={handleSelectOption}
                  className={classNames('text-xs p-3 min-h-[40px] text-ui-gray11 cursor-pointer', {
                    'border-t border-ui-gray4': index !== 0,
                  })}
                >
                  {option.label}
                </option>
              ))
            ) : (
              <div className={'text-xs p-3 text-ui-gray11 cursor-pointer'}>
                No options found for "{filterValue}"
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
