import { useEffect, useState } from 'react';
import { AxiosController } from '@utils/axiosController';

import LocationPin from '@assets/icons/locationPin';
import mockLocation1 from '@assets/mockLocation1.png';
import mockLocation2 from '@assets/mockLocation2.png';
import mockLocation3 from '@assets/mockLocation3.png';

import { ReportsShowcaseProps } from './ReportsShowcase.types';
import {useKeycloak} from "@react-keycloak/web";

const getPropertyPhotosUrls = (photos: Record<string, string>[]) => {
  const photosUrls: string[] = [];

  photos.forEach((photo) => {
    if (photo.type !== 'photo') {
      return;
    }

    photosUrls.push(photo.url);
  });

  return photosUrls;
};

export const ReportsShowcase = ({
  name,
  address,
  company,
  chain,
  longitude,
  latitude,
}: ReportsShowcaseProps) => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const [clientLogoSrc, setClientLogoSrc] = useState<string>();
  const [companyLogo, setCompanyLogo] = useState<string>();
  const [loadingClientLogo, setLoadingClientLogo] = useState<boolean>(true);
  const [loadingPhotos, setLoadingPhotos] = useState<boolean>(true);
  const [propertyPhotos, setPropertyPhotos] = useState<any>();
  const { get } = AxiosController(setLoadingPhotos);
  const { getFile } = AxiosController(setLoadingClientLogo);

  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      try {
        if (chain && longitude && latitude) {
          const promises: any[] = [
            get(`/google-places/photos?name=${encodeURI(chain)}&lon=${longitude}&lat=${latitude}`),

          ]
          if(company.logo){
            promises.push(getFile(encodeURI(company.logo)));
          }
          const [photos, logo] = await Promise.all(promises);

          const propertyPhotosUrls = getPropertyPhotosUrls(photos?.items);

          setPropertyPhotos(propertyPhotosUrls);
          if(logo?.url  ) {
            setClientLogoSrc(logo?.url);
          }
        }
      } catch (error) {
        console.error(error);
      }

      if (chain) {
        const path = `${encodeURI(chain?.replaceAll(' ', '_'))}`;
        const chainLogoPath = '/brands/' + path;
        setCompanyLogo( chainLogoPath);

      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <section className="grid grid-cols-2">
      <div className="flex flex-col gap-8">
        {!!companyLogo ? (
            <img src={companyLogo + '.png'} alt={`${chain} logo`} className="w-max h-[78px] animate-fadein"
                 onError={(e: any) => (e.target.onerror = null, e.target.src = companyLogo + '.svg')} />
        ) : (
          <div className="w-max h-[78px] animate-fadein"></div>
        )}

        <div className="flex flex-col gap-2">
          <h1 className="text-4xl font-semibold">{name}</h1>

          <div className="flex items-center gap-1">
            <LocationPin />
            <span className="text-sm font-medium text-light-neutralTextWeak">{address}</span>
          </div>
        </div>

        <div className="flex items-center border border-solid rounded-lg border-light-neutralBorder w-fit">
          <span className="text-xs font-normal text-light-neutralTextWeak px-3 py-[9px]">
            Prepared exclusively for:{' '}
            <span className="text-sm leading-4 text-light-neutralText">{company.name}</span>
          </span>

          {!loadingClientLogo && clientLogoSrc && (
            <div className="bg-light-neutralBackgroundBase px-3 py-[9px] rounded-r-lg">
              <img src={clientLogoSrc} alt={company.name} className="max-h-4 animate-fadein" />
            </div>
          )}
        </div>
      </div>

      {!loadingPhotos && (
        <div className="flex gap-1 overflow-hidden max-h-60 animate-fadein">
          <img
            className="object-cover w-3/4 border-2 rounded-lg shadow-lg grow border-light-neutralBackgroundBase max-h-60"
            src={propertyPhotos[0] ?? mockLocation1}
            alt="mock_picture1"
          />

          <div className="grid w-1/4 grid-rows-2 gap-1 grow">
            <img
              className="block object-cover w-full h-full border-2 rounded-lg shadow-lg border-light-neutralBackgroundBase"
              src={propertyPhotos[1] ?? mockLocation2}
              alt="mock_picture2"
            />
            <img
              className="block object-cover w-full h-full border-2 rounded-lg shadow-lg border-light-neutralBackgroundBase"
              src={propertyPhotos[2] ?? mockLocation3}
              alt="mock_picture3"
            />
          </div>
        </div>
      )}
    </section>
  );
};
