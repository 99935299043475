interface Props {
  open: boolean;
}

export const SelectChevronDown = ({ open }: Props) => {
  return (
    <svg
      className={open ? 'transform rotate-180' : ''}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11L3 6.00002L3.7 5.30002L8 9.60002L12.3 5.30002L13 6.00002L8 11Z"
        fill="#444756"
      />
    </svg>
  );
};
