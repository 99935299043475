import styled, { css } from 'styled-components';

import * as Tokens from '../../tokens/tokens';

const size = ($size: 'lg' | 'md' | 'sm' | 'xs') => {
  switch ($size) {
    case 'lg': {
      return Tokens.AvatarSizeLg;
    }
    case 'md': {
      return Tokens.AvatarSizeMd;
    }
    case 'sm': {
      return Tokens.AvatarSizeSm;
    }
    case 'xs': {
      return Tokens.AvatarSizeXs;
    }
    default: {
      return Tokens.AvatarSizeLg;
    }
  }
};

export const AvatarWrapper = styled.div<{
  $size: 'lg' | 'md' | 'sm' | 'xs';
  $selected?: boolean;
  $disabled?: boolean;
  $empty?: boolean;
}>`
  width: ${({ $size }) => size($size)};
  height: ${({ $size }) => size($size)};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme['avatarBackground']['empty']};
  border: 1px solid ${({ theme }) => theme['avatarBorder']['default']};

  ${({ $empty }) =>
    $empty &&
    css`
      border: none;
    `}

  &:hover {
    border-color: ${({ theme }) => theme['avatarBorder']['hover']};
    cursor: pointer;
  }

  &:active {
    border-color: ${({ theme }) => theme['avatarBorder']['active']} !important;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      filter: grayscale(100);
      pointer-events: none;
      cursor: not-allowed;
      border: none;
    `};
`;

export const Image = styled.img<{
  $size: 'lg' | 'md' | 'sm' | 'xs';
  $empty?: boolean;
}>`
  width: ${({ $size }) => size($size)};
  height: ${({ $size }) => size($size)};
  opacity: ${({ $empty }) => ($empty ? 0 : 1)};
  border-radius: 100%;
`;

export const Name = styled.div<{
  $size: 'lg' | 'md' | 'sm' | 'xs';
  $empty?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $empty, theme }) =>
    $empty ? theme['avatarBackground']['empty'] : theme['avatarBackground']['default']};
  opacity: ${({ $empty }) => ($empty ? 0 : 1)};
  width: ${({ $size }) => size($size)};
  height: ${({ $size }) => size($size)};
  border-radius: 100%;
`;
