import { useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const useMapbox = () => {
  const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

  return useMemo(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    return mapboxgl;
  }, [mapboxAccessToken]);
};

export default useMapbox;
