const NearestCompetitorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <rect width="16" height="16" fill="#fff" rx="8" />
      <path
        fill="#36C4A5"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm0 3a.75.75 0 1 1 0 1.5A.75.75 0 0 1 8 4Zm2 8.063H6v-1.126h1.438V8.063H6.5V6.937h2.063v4H10v1.126Z"
      />
    </svg>
  );
};

export default NearestCompetitorIcon;
