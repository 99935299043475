import React from 'react';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { useTheme } from 'styled-components';
import * as allCurves from '@visx/curve';

import { LineChartVizProps } from './LineChart.types';
import { LineChartVizWrapper, PointLabelWrapper } from './LineChartViz.styles';

export const LineChartViz = ({
  data,
  getX,
  xScale,
  yScale,
  dataKeysY,
  colorPalette,
  colorIndex,
  handleMouseMove,
  handleMouseLeave,
  curveType = 'curveLinear',
  pointerEvents = 'none',
  withPointsLabels,
  pointLabelFormatter,
}: LineChartVizProps) => {
  const theme = useTheme();

  return dataKeysY.map((dataKey, index) => (
    <LineChartVizWrapper key={`areaChartPath-${dataKey}`} $pointerEvents={pointerEvents}>
      <LinePath
        curve={allCurves[curveType]}
        data={data}
        x={(d) => (xScale(getX(d)) ?? 0) + xScale.bandwidth() / 2}
        y={(d) => yScale(d[dataKey] as number) ?? 0}
        stroke={theme['dataViz'][colorPalette][colorIndex + index]}
        strokeWidth={1.5}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
      />

      {data.map((d, i) => (
        <Group
          key={`point-${i}-${getX(d)}-${d[dataKey]}`}
          {...(handleMouseMove
            ? {
                onMouseMove: (event) => {
                  handleMouseMove(event, {
                    color: theme['dataViz'][colorPalette][colorIndex + index],
                    data: {
                      key: getX(d),
                      index: dataKey,
                      value: d[dataKey],
                    },
                  });
                },
                onTouchMove: (event) => {
                  handleMouseMove(event, {
                    color: theme['dataViz'][colorPalette][colorIndex + index],
                    data: {
                      key: getX(d),
                      index: dataKey,
                      value: d[dataKey],
                    },
                  });
                },
              }
            : {})}
          {...(handleMouseLeave
            ? {
                onMouseLeave: handleMouseLeave,
                onTouchEnd: handleMouseLeave,
              }
            : {})}
        >
          <circle
            key={`point-bg-1-${i}-${getX(d)}-${d[dataKey]}`}
            cx={(xScale(getX(d)) ?? 0) + xScale.bandwidth() / 2}
            cy={yScale(d[dataKey] as number)}
            r={8}
            fill={'transparent'}
            fillOpacity={0}
          />
          <circle
            key={`point-bg-2-${i}-${getX(d)}-${d[dataKey]}`}
            cx={(xScale(getX(d)) ?? 0) + xScale.bandwidth() / 2}
            cy={yScale(d[dataKey] as number)}
            r={4}
            fill={theme['neutralBackgroundBase']}
            fillOpacity={1}
          />
          <circle
            key={`point-${i}-${getX(d)}-${d[dataKey]}`}
            cx={(xScale(getX(d)) ?? 0) + xScale.bandwidth() / 2}
            cy={yScale(d[dataKey] as number)}
            r={2}
            fill={theme['neutralBackgroundBase']}
            stroke={theme['dataViz'][colorPalette][colorIndex + index]}
            strokeWidth={1.5}
          />

          {/* Point Label */}
          {withPointsLabels && (
            <foreignObject
              x={(xScale(getX(d)) ?? 0) + xScale.bandwidth() / 2}
              y={yScale(d[dataKey] as number)}
              width="0"
              height="0"
              style={{
                position: 'relative',
                overflow: 'visible',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            >
              <PointLabelWrapper>
                {pointLabelFormatter ? pointLabelFormatter(d[dataKey]) : d[dataKey]}
              </PointLabelWrapper>
            </foreignObject>
          )}
        </Group>
      ))}
    </LineChartVizWrapper>
  ));
};
