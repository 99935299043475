import styled from 'styled-components';

export const ScoreWrapperStyled = styled.div<{ $score: number; $baseScore: number }>`
  &::before {
    --rotate-to: ${({ $score }) => `${$score * 3.6}deg`};
    content: '';
    position: absolute;
    translate: 0 -50%;
    width: 16px;
    height: 74px;
    background-image: url(/assets/icons/cfs-score-arrow.svg);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    z-index: -1;
    transform-origin: bottom;
    rotate: ${({ $score }) => `${$score * 0.6 * 3.6}deg`};
    opacity: 0;
    animation:
      rotate-to 0.6s 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      fadein 0.6s 0.8s ease forwards;
  }

  &::after {
    --rotate-to: ${({ $baseScore }) => `${$baseScore * 3.6}deg`};
    content: '';
    position: absolute;
    translate: 0 -50%;
    width: 16px;
    height: 116px;
    background-image: url(/assets/icons/baseline-score-arrow.svg);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    z-index: -1;
    transform-origin: bottom;
    rotate: ${({ $baseScore }) => `${$baseScore * 0.6 * 3.6}deg`};
    opacity: 0;
    animation:
      rotate-to 0.6s 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      fadein 0.6s 1.2s ease forwards;
  }

  @keyframes rotate-to {
    to {
      rotate: var(--rotate-to);
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
