import React from 'react';
import { useTheme } from 'styled-components';

import { LegendContainer, LegendItem } from './Legend.styles';

export interface LegendProps {
  labels: string[];
  colorPalette?: string;
  direction?: 'horizontal' | 'vertical';
  height?: string;
  width?: string;
}

export const Legend = ({
  labels,
  colorPalette = 'qualitative_2_1',
  direction = 'horizontal',
  height = '100%',
  width = '100%',
}: LegendProps) => {
  const theme = useTheme();

  return (
    <LegendContainer $direction={direction} $height={height} $width={width}>
      {labels.map((label, index) => (
        <LegendItem
          key={`${label}-${index}`}
          $color={theme['dataViz'][colorPalette][index] ?? theme['dataViz'][colorPalette][0]}
        >
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};
