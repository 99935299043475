const MapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M8.33337 12.3333L5.28837 8.03331C4.78996 7.44485 4.469 6.72689 4.36285 5.96306C4.25671 5.19923 4.36975 4.42096 4.68883 3.71889C5.0079 3.01682 5.51987 2.41985 6.1651 1.99751C6.81034 1.57516 7.56229 1.34482 8.33337 1.33331C9.40225 1.34388 10.4234 1.77761 11.1731 2.53952C11.9228 3.30143 12.34 4.32941 12.3334 5.39831C12.3338 6.33078 12.0163 7.23553 11.4334 7.96331L8.33337 12.3333ZM8.33337 2.33331C7.5293 2.34255 6.76176 2.6705 6.19928 3.24517C5.6368 3.81983 5.32537 4.59423 5.33337 5.39831C5.33672 6.13222 5.60076 6.84106 6.07837 7.39831L8.33337 10.5933L10.6484 7.33331C11.0884 6.78404 11.3298 6.10211 11.3334 5.39831C11.3414 4.59423 11.0299 3.81983 10.4675 3.24517C9.90499 2.6705 9.13745 2.34255 8.33337 2.33331Z"
        fill="#111111"
      />
      <path
        d="M8.33337 5.83331C8.88566 5.83331 9.33337 5.3856 9.33337 4.83331C9.33337 4.28103 8.88566 3.83331 8.33337 3.83331C7.78109 3.83331 7.33337 4.28103 7.33337 4.83331C7.33337 5.3856 7.78109 5.83331 8.33337 5.83331Z"
        fill="#111111"
      />
      <path
        d="M14.3334 6.33331H13.3334V7.33331H14.3334V14.3333H2.33337V7.33331H3.33337V6.33331H2.33337C2.06816 6.33331 1.8138 6.43867 1.62627 6.62621C1.43873 6.81374 1.33337 7.0681 1.33337 7.33331V14.3333C1.33337 14.5985 1.43873 14.8529 1.62627 15.0404C1.8138 15.228 2.06816 15.3333 2.33337 15.3333H14.3334C14.5986 15.3333 14.8529 15.228 15.0405 15.0404C15.228 14.8529 15.3334 14.5985 15.3334 14.3333V7.33331C15.3334 7.0681 15.228 6.81374 15.0405 6.62621C14.8529 6.43867 14.5986 6.33331 14.3334 6.33331Z"
        fill="#111111"
      />
    </svg>
  );
};

export default MapIcon;
