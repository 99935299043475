import React from 'react';

import { CoreChartBase } from '../CoreChartBase';
import { LineChartViz } from './LineChartViz';
import { LineChartProps } from './LineChart.types';

export const LineChart = (props: LineChartProps) => {
  return (
    <CoreChartBase
      {...{
        ...props,
        hasGridColumnsOffset: true,
        hasGridColumnsPadding: false,
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <LineChartViz
        {...{
          withPointsLabels: props.withPointsLabels,
          pointLabelFormatter: props.pointLabelFormatter,
        }}
      />
    </CoreChartBase>
  );
};
