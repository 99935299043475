type Props = {
  fill?: string;
};

const Info = ({ fill = '#444756' }: Props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.752014C3.1125 0.752014 0.75 3.11451 0.75 6.00201C0.75 8.88951 3.1125 11.252 6 11.252C8.8875 11.252 11.25 8.88951 11.25 6.00201C11.25 3.11451 8.8875 0.752014 6 0.752014ZM5.5875 3.00201H6.4125V7.12701H5.5875V3.00201ZM6 9.37702C5.7 9.37702 5.4375 9.11452 5.4375 8.81452C5.4375 8.51451 5.7 8.25201 6 8.25201C6.3 8.25201 6.5625 8.51451 6.5625 8.81452C6.5625 9.11452 6.3 9.37702 6 9.37702Z"
        fill={fill}
      />
    </svg>
  );
};

export default Info;
