export const ShapValueLegend = () => {
  return (
    <div className="w-full h-8 overflow-visible justify-stretch">
      <div className="text-xs text-center">SHAP value</div>

      <div className="w-full h-2 mt-1 rounded-lg grow bg-shapValueLegendGradient"></div>

      <div className="flex justify-between text-light-neutralTextWeaker text-[10px] leading-3 w-full">
        <span>-2</span>
        <span>0</span>
        <span>2</span>
      </div>
    </div>
  );
};
