const MainCompetitorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <rect width="16" height="16" fill="#fff" rx="8" />
      <path
        fill="#473DD9"
        d="M8 1C4.15 1 1 4.15 1 8s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7Zm-.55 3h1.1v5.5h-1.1V4ZM8 12.5c-.4 0-.75-.35-.75-.75S7.6 11 8 11s.75.35.75.75-.35.75-.75.75Z"
      />
    </svg>
  );
};

export default MainCompetitorIcon;
