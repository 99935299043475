import Dropzone from 'react-dropzone';

import { ArrowRight } from '@assets/icons/ArrowRight';
import { UploadIcon } from '@assets/icons/UploadIcon';
import { AutoCompleteSelect } from '@components/AutoCompleteSelect';
import { Input } from '@components/Input';
import { useEffect, useState } from 'react';
import { AxiosController } from '@utils/axiosController';
import Loader from '@components/Loader';

interface Step1Props {
  values: Record<string, any>;
  errors: Record<string, any>;
  touched: Record<string, any>;
  handleChange: (e: any) => void;
  handleNextStep: () => void;
  setValues: (values: any) => void;
}

export const Step1 = ({
  values,
  errors,
  touched,
  handleChange,
  handleNextStep,
  setValues,
}: Step1Props) => {
  const [options, setOptions] = useState([{ value: 'value', label: 'label' }]);
  const [loading, setLoading] = useState<boolean>(true);
  const { get } = AxiosController(setLoading);

  const onDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    Promise.resolve(
      acceptedFiles.forEach((file: any) => {
        formData.append('files', file);
      }),
    ).then(async () => {
      setValues({ ...values, files: formData });
    });
  };
  const getOptions = async () => {
    const result = await get('/clients');
    const options = result?.items?.map((el: any) => {
      return { value: el._id, label: el.company.name };
    });
    setOptions(options);
  };

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loader size="lg" />
  ) : (
    <>
      <h3 className="text-xl">New or Existing client?</h3>
      <AutoCompleteSelect
        id={'existing'}
        placeholder={'Lookup existing client'}
        values={values.existing}
        disabled={values.name || values.email || values.company}
        errors={errors.existing}
        touched={touched.existing}
        options={options}
        handleChange={handleChange}
      />
      <div className="flex gap-7.5 items-center py-2">
        <span className="w-full border-t border-dark-neutralBorder"></span>
        <div className="text-sm font-medium min-w-max text-light-neutralTextWeak">Create New</div>
        <span className="w-full border-t border-dark-neutralBorder"></span>
      </div>
      <div className="flex flex-col gap-4">
        <Input
          id={'name'}
          handleChange={handleChange}
          disabled={values.existing}
          values={values.name}
          errors={errors.name}
          touched={touched.name}
          label="Name"
        />
        <Input
          id={'email'}
          handleChange={handleChange}
          disabled={values.existing}
          values={values.email}
          errors={errors.email}
          touched={touched.email}
          label="Email"
        />
        <Input
          id={'company'}
          handleChange={handleChange}
          disabled={values.existing}
          values={values.company}
          errors={errors.company}
          touched={touched.company}
          label="Company"
        />
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-base text-light-neutralText">Company Logo</div>
        <Dropzone
          onDrop={onDrop}
          accept={{
            'image/*': ['.jpeg', '.png'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({ className: 'dropzone' })}
              className="flex items-center gap-4 rounded-lg bg-[#0E717B0A] border border-dashed py-3 px-4 border-light-borderTertiaryButton"
            >
              <input {...getInputProps()} />
              <UploadIcon />
              <div className="flex flex-col w-full">
                <div className="text-sm font-semibold text-light-neutralText">
                  Click or drag file to this area to upload
                </div>
                <div className="text-xs text-light-neutralTextWeak">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </div>
              </div>
              <span className="bg-[#0E717B14] py-2 px-3 text-xs text-light-primaryButton-default font-semibold rounded-lg w-max text-nowrap">
                Browse files
              </span>
            </div>
          )}
        </Dropzone>
      </div>
      <button
        onClick={handleNextStep}
        type="button"
        className="flex ml-auto gap-2 items-center bg-[#0E717B14] py-2 px-3 text-sm text-light-primaryButton-default font-semibold rounded-lg w-max text-nowrap"
      >
        Next
        <ArrowRight />
      </button>
    </>
  );
};
