export const SuccessModalIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="8" fill="url(#paint0_linear_898_75011)" />
      <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#012023" strokeOpacity="0.2" />
      <path
        d="M26.5 19.75C26.5 19.3358 26.8358 19 27.25 19H37.75C38.1642 19 38.5 19.3358 38.5 19.75V21.25C38.5 21.6642 38.1642 22 37.75 22H27.25C26.8358 22 26.5 21.6642 26.5 21.25V19.75Z"
        fill="#012023"
      />
      <path
        d="M19 17.5C17.3431 17.5 16 18.8431 16 20.5V23.5C16 25.1569 17.3431 26.5 19 26.5H22C23.6569 26.5 25 25.1569 25 23.5V20.5C25 18.8431 23.6569 17.5 22 17.5H19ZM19 29.5C17.3431 29.5 16 30.8431 16 32.5V35.5C16 37.1569 17.3431 38.5 19 38.5H22C23.6569 38.5 25 37.1569 25 35.5V32.5C25 30.8431 23.6569 29.5 22 29.5H19ZM20.2803 24.0303C19.9874 24.3232 19.5126 24.3232 19.2197 24.0303L17.7197 22.5303C17.4268 22.2374 17.4268 21.7626 17.7197 21.4697C18.0126 21.1768 18.4874 21.1768 18.7803 21.4697L19.75 22.4393L22.2197 19.9697C22.5126 19.6768 22.9874 19.6768 23.2803 19.9697C23.5732 20.2626 23.5732 20.7374 23.2803 21.0303L20.2803 24.0303ZM20.2803 36.0303C19.9874 36.3232 19.5126 36.3232 19.2197 36.0303L17.7197 34.5303C17.4268 34.2374 17.4268 33.7626 17.7197 33.4697C18.0126 33.1768 18.4874 33.1768 18.7803 33.4697L19.75 34.4393L22.2197 31.9697C22.5126 31.6768 22.9874 31.6768 23.2803 31.9697C23.5732 32.2626 23.5732 32.7374 23.2803 33.0303L20.2803 36.0303Z"
        fill="#012023"
      />
      <path
        d="M26.5 31.75C26.5 31.3358 26.8358 31 27.25 31H37.75C38.1642 31 38.5 31.3358 38.5 31.75V33.25C38.5 33.6642 38.1642 34 37.75 34H27.25C26.8358 34 26.5 33.6642 26.5 33.25V31.75Z"
        fill="#012023"
      />
      <path
        d="M26.5 24.25C26.5 23.8358 26.8358 23.5 27.25 23.5H34.75C35.1642 23.5 35.5 23.8358 35.5 24.25C35.5 24.6642 35.1642 25 34.75 25H27.25C26.8358 25 26.5 24.6642 26.5 24.25Z"
        fill="#012023"
      />
      <path
        d="M26.5 36.25C26.5 35.8358 26.8358 35.5 27.25 35.5H34.75C35.1642 35.5 35.5 35.8358 35.5 36.25C35.5 36.6642 35.1642 37 34.75 37H27.25C26.8358 37 26.5 36.6642 26.5 36.25Z"
        fill="#012023"
      />
      <defs>
        <linearGradient
          id="paint0_linear_898_75011"
          x1="7.58333"
          y1="7.875"
          x2="18.1573"
          y2="56.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
