export const ModalIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="8" fill="url(#paint0_linear_898_74007)" />
      <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#012023" strokeOpacity="0.2" />
      <g clipPath="url(#clip0_898_74007)">
        <rect x="16" y="16" width="24" height="24" rx="12" fill="white" />
        <path
          d="M28 38.5C22.201 38.5 17.5 33.799 17.5 28C17.5 22.201 22.201 17.5 28 17.5C33.799 17.5 38.5 22.201 38.5 28C38.5 33.799 33.799 38.5 28 38.5ZM28 40C34.6274 40 40 34.6274 40 28C40 21.3726 34.6274 16 28 16C21.3726 16 16 21.3726 16 28C16 34.6274 21.3726 40 28 40Z"
          fill="#012023"
        />
        <path
          d="M28 22C28.4142 22 28.75 22.3358 28.75 22.75V27.25H33.25C33.6642 27.25 34 27.5858 34 28C34 28.4142 33.6642 28.75 33.25 28.75H28.75V33.25C28.75 33.6642 28.4142 34 28 34C27.5858 34 27.25 33.6642 27.25 33.25V28.75H22.75C22.3358 28.75 22 28.4142 22 28C22 27.5858 22.3358 27.25 22.75 27.25H27.25V22.75C27.25 22.3358 27.5858 22 28 22Z"
          fill="#012023"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_898_74007"
          x1="7.58333"
          y1="7.875"
          x2="18.1573"
          y2="56.0664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
        <clipPath id="clip0_898_74007">
          <rect x="16" y="16" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
