const Cheveron = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.47485 10L12.7249 3.75L13.5999 4.625L8.22485 10L13.5999 15.375L12.7249 16.25L6.47485 10Z"
        fill="#02484F"
      />
    </svg>
  );
};

export default Cheveron;
