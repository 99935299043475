/**
 * Do not edit directly
 * Generated on Fri, 03 May 2024 12:20:11 GMT
 */

export const AvatarSizeXs = '20px';
export const AvatarSizeSm = '24px';
export const AvatarSizeMd = '32px';
export const AvatarSizeLg = '40px';
export const AvatarBorderDefault = '#e4e6f4';
export const AvatarBorderHover = '#d6daea';
export const AvatarBorderActive = '#6dadfb';
export const AvatarBackgroundColorDefault = '#e4e6f4';
export const AvatarBackgroundColorEmpty = '#daeaff';
export const BadgeInteractiveTextDefault = '#444756';
export const BadgeInteractiveTextInverted = '#f1f3ff';
export const BadgeInteractiveTextSelected = '#f1f7ff';
export const BadgeInteractivePaddingSmPx = '2px';
export const BadgeInteractivePaddingSmPy = '0px';
export const BadgeInteractivePaddingMdPx = '4px';
export const BadgeInteractivePaddingMdPy = '0px';
export const BadgeInteractivePaddingLgPx = '4px';
export const BadgeInteractivePaddingLgPy = '2px';
export const BadgeInteractiveFontSmSize = '10px';
export const BadgeInteractiveFontSmLineHeight = '14px';
export const BadgeInteractiveFontMdSize = '12px';
export const BadgeInteractiveFontMdLineHeight = '16px';
export const BadgeInteractiveFontLgSize = '12px';
export const BadgeInteractiveFontLgLineHeight = '16px';
export const BadgeInteractiveBackgroundColorDafault = '#f1f3ff';
export const BadgeInteractiveBackgroundColorHover = '#e4e6f4';
export const BadgeInteractiveBackgroundColorPressed = '#d6daea';
export const BadgeInteractiveBackgroundColorSelected = '#005dcf';
export const BadgeInteractiveBackgroundColorDisabled = '#e4e6f4';
export const BadgeInteractiveBackgroundColorEmpty = '#f1f3ff';
export const BadgeInteractiveBackgroundColorFocus = '#f1f3ff';
export const BadgeStatusTextDefault = '#22242c';
export const BadgeStatusTextInverted = '#fff1f1';
export const BadgeStatusTextError = '#660000';
export const BadgeStatusTextSuccess = '#006625';
export const BadgeStatusTextWarning = '#665100';
export const BadgeStatusTextInfo = '#004766';
export const BadgeStatusBackgroundColorDafaultSaturated = '#22242c';
export const BadgeStatusBackgroundColorDafaultMuted = '#56596B1F';
export const BadgeStatusBackgroundColorErrorSaturated = '#cf0000';
export const BadgeStatusBackgroundColorErrorMuted = '#F445451F';
export const BadgeStatusBackgroundColorSuccessSaturated = '#07e458';
export const BadgeStatusBackgroundColorSuccessMuted = '#45F4851F';
export const BadgeStatusBackgroundColorWarningSaturated = '#efc417';
export const BadgeStatusBackgroundColorWarningMuted = '#F4D14533';
export const BadgeStatusBackgroundColorInfoSaturated = '#2eb9f5';
export const BadgeStatusBackgroundColorInfoMuted = '#07A2E41F';
export const ButtonRadiusDefault = '8px';
export const ButtonTextPrimary = '#F1FEFF';
export const ButtonTextPrimaryDisabled = '#1D8792';
export const ButtonTextSecondary = '#012023';
export const ButtonTextSecondaryGray = '#22242C';
export const ButtonTextTertiary = '#02484F';
export const ButtonTextTertiaryDisabled = '#1D8792';
export const ButtonPaddingSmPx = '12px';
export const ButtonPaddingSmPy = '8px';
export const ButtonPaddingMdPx = '12px';
export const ButtonPaddingMdPy = '8px';
export const ButtonPaddingLgPx = '16px';
export const ButtonPaddingLgPy = '12px';
export const ButtonFontSmSize = '12px';
export const ButtonFontSmLineHeight = '16px';
export const ButtonFontMdSize = '14px';
export const ButtonFontMdLineHeight = '20px';
export const ButtonFontLgSize = '14px';
export const ButtonFontLgLineHeight = '20px';
export const ButtonBackgroundColorPrimaryDefault = '#02484F';
export const ButtonBackgroundColorPrimaryHover = '#013439';
export const ButtonBackgroundColorPrimaryActive = '#013439';
export const ButtonBackgroundColorPrimaryFocus = '#02484F';
export const ButtonBackgroundColorPrimaryEmpty = '#D2F1F4';
export const ButtonBackgroundColorPrimaryDisabled = '#0E717B14';
export const ButtonBackgroundColorPrimaryGrayDefault = '#333641';
export const ButtonBackgroundColorPrimaryGrayHover = '#444756';
export const ButtonBackgroundColorPrimaryGrayActive = '#22242c';
export const ButtonBackgroundColorPrimaryGrayFocus = '#22242c';
export const ButtonBackgroundColorPrimaryGrayEmpty = '#daeaff';
export const ButtonBackgroundColorPrimaryGrayDisabled = '#56596B14';
export const ButtonBackgroundColorSecondaryDefault = '#0E717B14';
export const ButtonBackgroundColorSecondaryHover = '#0E717B1F';
export const ButtonBackgroundColorSecondaryActive = '#99D8DF';
export const ButtonBackgroundColorSecondaryFocus = '#0E717B14';
export const ButtonBackgroundColorSecondaryEmpty = '#D2F1F4';
export const ButtonBackgroundColorSecondaryDisabled = '#56596B14';
export const ButtonBackgroundColorSecondaryGrayDefault = '#56596B14';
export const ButtonBackgroundColorSecondaryGrayHover = '#56596B1F';
export const ButtonBackgroundColorSecondaryGrayActive = '#56596B29';
export const ButtonBackgroundColorSecondaryGrayFocus = '#56596B14';
export const ButtonBackgroundColorSecondaryGrayEmpty = '#D2F1F4';
export const ButtonBackgroundColorSecondaryGrayDisabled = '#56596B14';
export const ButtonBackgroundColorTertiaryDefault = 'transparent';
export const ButtonBackgroundColorTertiaryHover = '#0E717B1F';
export const ButtonBackgroundColorTertiaryActive = '#99D8DF';
export const ButtonBackgroundColorTertiaryFocus = '#FFFFFF';
export const ButtonBackgroundColorTertiaryEmpty = '#D2F1F4';
export const ButtonBackgroundColorTertiaryDisabled = 'transparent';
export const ButtonBackgroundColorGhostDefault = 'transparent';
export const ButtonBackgroundColorGhostHover = '#0E717B1F';
export const ButtonBackgroundColorGhostActive = '#99D8DF';
export const ButtonBackgroundColorGhostFocus = '#FFFFFF';
export const ButtonBackgroundColorGhostEmpty = '#D2F1F4';
export const ButtonBackgroundColorGhostDisabled = 'transparent';
export const ButtonBackgroundColorErrorDefault = '#cf0000';
export const ButtonBackgroundColorErrorHover = '#a20000';
export const ButtonBackgroundColorErrorActive = '#660000';
export const ButtonBackgroundColorErrorFocus = '#cf0000';
export const ButtonBorderDefault = 'transparent';
export const ButtonBorderTertiary = '#55B4BE';
export const CheckboxFontSmSize = '12px';
export const CheckboxFontSmLineHeight = '16px';
export const CheckboxFontMdSize = '14px';
export const CheckboxFontMdLineHeight = '20px';
export const CheckboxUncheckedBackdroundDefault = 'transparent';
export const CheckboxUncheckedBackdroundHover = '#e4e6f4';
export const CheckboxUncheckedBackdroundPressed = '#d6daea';
export const CheckboxUncheckedBorderDefault = '#444756';
export const CheckboxUncheckedBorderError = '#660000';
export const CheckboxUncheckedBorderDisabled = '#7b8096';
export const CheckboxCheckedBackdroundDefault = '#005dcf';
export const CheckboxCheckedBackdroundDisabled = '#7b8096';
export const CheckboxCheckedBackdroundError = '#660000';
export const LinkTextDefault = '#005dcf';
export const LinkTextHover = '#0048a0';
export const LinkTextPressed = '#002d64';
export const LinkTextSelected = '#005dcf';
export const LinkTextFocus = '#6dadfb';
export const LinkTextError = '#660000';
export const LinkFontSmSize = '12px';
export const LinkFontSmLineHeight = '16px';
export const LinkFontMdSize = '14px';
export const LinkFontMdLineHeight = '20px';
export const LinkBackgroundColorDefault = '#005dcf';
export const LinkBackgroundColorHover = '#0048a0';
export const LinkBackgroundColorPressed = '#002d64';
export const LinkBackgroundColorSelected = '#005dcf';
export const LinkBackgroundColorFocus = '#6dadfb';
export const LinkBackgroundColorError = '#660000';
export const LinkBackgroundColorEmpty = '#daeaff';
export const LinkRadius = '32px';
export const LoaderBackground = '#f1f3ff';
export const LoaderAccent = '#005dcf';
export const LightNeutralTransparentBackdrop = '#22242CB8';
export const LightNeutralTransparentBackgroundTransparentStrong = '#56596B29';
export const LightNeutralTransparentBackgroundTransparent = '#56596B1F';
export const LightNeutralTransparentBackgroundTransparentWeak = '#56596B14';
export const LightNeutralTransparentBackgroundTransparentWeaker = '#56596B0A';
export const LightNeutralTextDefault = '#22242c';
export const LightNeutralTextWeak = '#5f6877';
export const LightNeutralTextWeaker = '#686c80';
export const LightNeutralTextWeakest = '#a6aac0';
export const LightNeutralTextInverted = '#e4e6f4';
export const LightNeutralTextInvertedWeak = '#cacddf';
export const LightNeutralTextInvertedWeaker = '#a6aac0';
export const LightNeutralTextInvertedWeakest = '#686c80';
export const LightNeutralIconDefault = '#22242c';
export const LightNeutralIconWeak = '#444756';
export const LightNeutralIconWeaker = '#686c80';
export const LightNeutralIconWeakest = '#a6aac0';
export const LightNeutralIconInverted = '#e4e6f4';
export const LightNeutralIconInvertedWeak = '#cacddf';
export const LightNeutralIconInvertedWeaker = '#a6aac0';
export const LightNeutralIconInvertedWeakest = '#686c80';
export const LightNeutralBackgroundDefault = '#f1f4f7';
export const LightNeutralBackgroundBase = '#FFFFFF';
export const LightNeutralBackgroundBaseInverted = '#111111';
export const LightNeutralBackgroundStrongest = '#22242c';
export const LightNeutralBackgroundStronger = '#333641';
export const LightNeutralBackgroundStrong = '#444756';
export const LightNeutralBackgroundHover = '#e4e6f4';
export const LightNeutralBackgroundActive = '#d6daea';
export const LightNeutralBackgroundExtraDark = '#141C2C';
export const LightNeutralBorderDefault = '#d3dbe3';
export const LightNeutralBorderWeakest = '#e4e6f4';
export const LightNeutralBorderWeakestHover = '#9094ab';
export const LightNeutralBorderWeakestActive = '#7b8096';
export const LightPrimaryTransparentBackgroundTransparentStrong = '#076AE429';
export const LightPrimaryTransparentBackgroundTransparent = '#076AE41F';
export const LightPrimaryTransparentBackgroundTransparentWeak = '#076AE414';
export const LightPrimaryTransparentBackgroundTransparentWeaker = '#076AE40A';
export const LightPrimaryTextDefault = '#002d64';
export const LightPrimaryTextWeak = '#005dcf';
export const LightPrimaryTextWeaker = '#1778ef';
export const LightPrimaryTextWeakest = '#2e87f5';
export const LightPrimaryTextInverted = '#f1f7ff';
export const LightPrimaryTextInvertedWeak = '#acd1fe';
export const LightPrimaryTextInvertedWeakest = '#4a99f9';
export const LightPrimaryIconDefault = '#002d64';
export const LightPrimaryIconWeak = '#005dcf';
export const LightPrimaryIconWeaker = '#1778ef';
export const LightPrimaryIconWeakest = '#2e87f5';
export const LightPrimaryIconInverted = '#f1f7ff';
export const LightPrimaryIconInvertedWeak = '#acd1fe';
export const LightPrimaryIconInvertedWeakest = '#4a99f9';
export const LightPrimaryBackgroundDefault = '#f1f7ff';
export const LightPrimaryBackgroundStrongest = '#012023';
export const LightPrimaryBackgroundStronger = '#0048a0';
export const LightPrimaryBackgroundStrong = '#005dcf';
export const LightPrimaryBackgroundHover = '#daeaff';
export const LightPrimaryBackgroundActive = '#c3defe';
export const LightPrimaryBorderDefault = '#6dadfb';
export const LightPrimaryBorderHover = '#daeaff';
export const LightPrimaryBorderActive = '#4a99f9';
export const LightPrimaryBorderDisabled = '#2e87f5';
export const LightSecondaryTextDefault = '#181964';
export const LightSecondaryTextWeak = '#3a3dcf';
export const LightSecondaryIconDefault = '#181964';
export const LightSecondaryIconWeak = '#3a3dcf';
export const LightSecondaryBackgroundDefault = '#181964';
export const LightSecondaryBackgroundStrongest = '#f1f1ff';
export const LightSecondaryBorderDefault = '#9697fb';
export const LightSystemInfoTextDefault = '#004766';
export const LightSystemInfoTextInverted = '#f1fbff';
export const LightSystemInfoIconDefault = '#004766';
export const LightSystemInfoIconWeak = '#0091cf';
export const LightSystemInfoIconInverted = '#f1fbff';
export const LightSystemInfoBackgroundDefault = '#2eb9f5';
export const LightSystemInfoBackgroundHover = '#17aeef';
export const LightSystemInfoBackgroundActive = '#07a2e4';
export const LightSystemInfoBackgroundWeakest = '#daf4ff';
export const LightSystemInfoBackgroundWeakestHover = '#c3ecfe';
export const LightSystemInfoBackgroundWeakestActive = '#ace5fe';
export const LightSystemInfoBackgroundTransparent = '#07A2E41F';
export const LightSystemInfoBorderDefault = '#6dd1fb';
export const LightSystemSuccessTextDefault = '#006625';
export const LightSystemSuccessTextInverted = '#f1fff6';
export const LightSystemSuccessIconDefault = '#006625';
export const LightSystemSuccessIconWeak = '#00cf4c';
export const LightSystemSuccessIconInverted = '#f1fff6';
export const LightSystemSuccessBackgroundDefault = '#07e458';
export const LightSystemSuccessBackgroundHover = '#00cf4c';
export const LightSystemSuccessBackgroundActive = '#00a23b';
export const LightSystemSuccessBackgroundWeakest = '#daffe7';
export const LightSystemSuccessBackgroundWeakestHover = '#c3fed9';
export const LightSystemSuccessBackgroundWeakestActive = '#acfeca';
export const LightSystemSuccessBackgroundTransparent = '#45F4851F';
export const LightSystemSuccessBorderDefault = '#6dfba1';
export const LightSystemWarningTextDefault = '#665100';
export const LightSystemWarningTextInverted = '#fffcf1';
export const LightSystemWarningIconDefault = '#665100';
export const LightSystemWarningIconWeak = '#cfa600';
export const LightSystemWarningIconInverted = '#fffcf1';
export const LightSystemWarningBackgroundDefault = '#e4b807';
export const LightSystemWarningBackgroundHover = '#cfa600';
export const LightSystemWarningBackgroundActive = '#a28100';
export const LightSystemWarningBackgroundWeakest = '#fff7da';
export const LightSystemWarningBackgroundWeakestHover = '#fef2c3';
export const LightSystemWarningBackgroundWeakestActive = '#feedac';
export const LightSystemWarningBackgroundTransparent = '#F4D14533';
export const LightSystemWarningBorderDefault = '#fbdf6d';
export const LightSystemWarningFill = '#efc417';
export const LightSystemErrorTextDefault = '#660000';
export const LightSystemErrorTextInverted = '#fff1f1';
export const LightSystemErrorIconDefault = '#660000';
export const LightSystemErrorIconWeak = '#cf0000';
export const LightSystemErrorIconInverted = '#fff1f1';
export const LightSystemErrorBackgroundDefault = '#cf0000';
export const LightSystemErrorBackgroundHover = '#a20000';
export const LightSystemErrorBackgroundActive = '#660000';
export const LightSystemErrorBackgroundWeakest = '#ffdada';
export const LightSystemErrorBackgroundWeakestHover = '#fec3c3';
export const LightSystemErrorBackgroundWeakestActive = '#feacac';
export const LightSystemErrorBackgroundTransparent = '#F445451F';
export const LightSystemErrorBorderDefault = '#fb6d6d';
export const DarkNeutralTransparentBackdrop = '#F1F3FFB8';
export const DarkNeutralTransparentBackgroundTransparentStrong = '#F1F3FF29';
export const DarkNeutralTransparentBackgroundTransparent = '#F1F3FF1F';
export const DarkNeutralTransparentBackgroundTransparentWeak = '#F1F3FF14';
export const DarkNeutralTransparentBackgroundTransparentWeaker = '#F1F3FF0A';
export const DarkNeutralTextDefault = '#f1f3ff';
export const DarkNeutralTextWeak = '#cacddf';
export const DarkNeutralTextWeaker = '#9094ab';
export const DarkNeutralTextWeakest = '#a6aac0';
export const DarkNeutralTextInverted = '#22242c';
export const DarkNeutralTextInvertedWeak = '#444756';
export const DarkNeutralTextInvertedWeaker = '#a6aac0';
export const DarkNeutralTextInvertedWeakest = '#686c80';
export const DarkNeutralIconDefault = '#e4e6f4';
export const DarkNeutralIconWeak = '#cacddf';
export const DarkNeutralIconWeaker = '#a6aac0';
export const DarkNeutralIconWeakest = '#686c80';
export const DarkNeutralIconInverted = '#22242c';
export const DarkNeutralIconInvertedWeak = '#444756';
export const DarkNeutralIconInvertedWeaker = '#686c80';
export const DarkNeutralIconInvertedWeakest = '#a6aac0';
export const DarkNeutralBackgroundDefault = '#121317';
export const DarkNeutralBackgroundBase = '#0B0C0E';
export const DarkNeutralBackgroundBaseInverted = '#FFFFFF';
export const DarkNeutralBackgroundStrongest = '#D8DBEA';
export const DarkNeutralBackgroundStronger = '#BFC3D5';
export const DarkNeutralBackgroundStrong = '#A7ABC0';
export const DarkNeutralBackgroundHover = '#1A1B21';
export const DarkNeutralBackgroundActive = '#23242C';
export const DarkNeutralBorderDefault = '#575A6B';
export const DarkNeutralBorderWeakest = '#23242C';
export const DarkNeutralBorderWeakestHover = '#2B2D37';
export const DarkNeutralBorderWeakestActive = '#343641';
export const DarkPrimaryTransparentBackgroundTransparentStrong = '#076AE43D';
export const DarkPrimaryTransparentBackgroundTransparent = '#076AE429';
export const DarkPrimaryTransparentBackgroundTransparentWeak = '#076AE41F';
export const DarkPrimaryTransparentBackgroundTransparentWeaker = '#076AE414';
export const DarkPrimaryTextDefault = '#c3defe';
export const DarkPrimaryTextWeak = '#acd1fe';
export const DarkPrimaryTextWeaker = '#4a99f9';
export const DarkPrimaryTextWeakest = '#2e87f5';
export const DarkPrimaryTextInverted = '#002d64';
export const DarkPrimaryTextInvertedWeak = '#005dcf';
export const DarkPrimaryTextInvertedWeakest = '#1778ef';
export const DarkPrimaryIconDefault = '#c3defe';
export const DarkPrimaryIconWeak = '#acd1fe';
export const DarkPrimaryIconWeaker = '#4a99f9';
export const DarkPrimaryIconWeakest = '#2e87f5';
export const DarkPrimaryIconInverted = '#002d64';
export const DarkPrimaryIconInvertedWeak = '#005dcf';
export const DarkPrimaryIconInvertedWeakest = '#1778ef';
export const DarkPrimaryBackgroundDefault = '#002d64';
export const DarkPrimaryBackgroundStrongest = '#daeaff';
export const DarkPrimaryBackgroundStronger = '#acd1fe';
export const DarkPrimaryBackgroundStrong = '#96c4fd';
export const DarkPrimaryBackgroundHover = '#0048a0';
export const DarkPrimaryBackgroundActive = '#005dcf';
export const DarkPrimaryBorderDefault = '#1778ef';
export const DarkPrimaryBorderHover = '#2e87f5';
export const DarkPrimaryBorderActive = '#4a99f9';
export const DarkPrimaryBorderDisabled = '#002d64';
export const DarkSecondaryTextDefault = '#d1d2fe';
export const DarkSecondaryTextWeak = '#b2b3fd';
export const DarkSecondaryIconDefault = '#d1d2fe';
export const DarkSecondaryIconWeak = '#b2b3fd';
export const DarkSecondaryBackgroundDefault = '#e1e2ff';
export const DarkSecondaryBackgroundStrongest = '#f1f1ff';
export const DarkSecondaryBorderDefault = '#c2c3fe';
export const DarkSystemInfoTextDefault = '#f1fbff';
export const DarkSystemInfoTextInverted = '#004766';
export const DarkSystemInfoIconDefault = '#f1fbff';
export const DarkSystemInfoIconWeak = '#4ac4f9';
export const DarkSystemInfoIconInverted = '#004766';
export const DarkSystemInfoBackgroundDefault = '#2eb9f5';
export const DarkSystemInfoBackgroundHover = '#4ac4f9';
export const DarkSystemInfoBackgroundActive = '#6dd1fb';
export const DarkSystemInfoBackgroundWeakest = '#004766';
export const DarkSystemInfoBackgroundWeakestHover = '#0071a2';
export const DarkSystemInfoBackgroundWeakestActive = '#f1fbff';
export const DarkSystemInfoBackgroundTransparent = '#07A2E41F';
export const DarkSystemInfoBorderDefault = '#07a2e4';
export const DarkSystemSuccessTextDefault = '#f1fff6';
export const DarkSystemSuccessTextInverted = '#006625';
export const DarkSystemSuccessIconDefault = '#f1fff6';
export const DarkSystemSuccessIconWeak = '#4af98a';
export const DarkSystemSuccessIconInverted = '#006625';
export const DarkSystemSuccessBackgroundDefault = '#2ef577';
export const DarkSystemSuccessBackgroundHover = '#4af98a';
export const DarkSystemSuccessBackgroundActive = '#6dfba1';
export const DarkSystemSuccessBackgroundWeakest = '#006625';
export const DarkSystemSuccessBackgroundWeakestHover = '#00a23b';
export const DarkSystemSuccessBackgroundWeakestActive = '#f1fff6';
export const DarkSystemSuccessBackgroundTransparent = '#45F4851F';
export const DarkSystemSuccessBorderDefault = '#07e458';
export const DarkSystemWarningTextDefault = '#fffcf1';
export const DarkSystemWarningTextInverted = '#665100';
export const DarkSystemWarningIconDefault = '#fffcf1';
export const DarkSystemWarningIconWeak = '#f9d64a';
export const DarkSystemWarningIconInverted = '#665100';
export const DarkSystemWarningBackgroundDefault = '#f5cd2e';
export const DarkSystemWarningBackgroundHover = '#f9d64a';
export const DarkSystemWarningBackgroundActive = '#fbdf6d';
export const DarkSystemWarningBackgroundWeakest = '#665100';
export const DarkSystemWarningBackgroundWeakestHover = '#a28100';
export const DarkSystemWarningBackgroundWeakestActive = '#fffcf1';
export const DarkSystemWarningBackgroundTransparent = '#F4D14533';
export const DarkSystemWarningBorderDefault = '#e4b807';
export const DarkSystemWarningFill = '#f9d64a';
export const DarkSystemErrorTextDefault = '#fff1f1';
export const DarkSystemErrorTextInverted = '#660000';
export const DarkSystemErrorIconDefault = '#fff1f1';
export const DarkSystemErrorIconWeak = '#f94a4a';
export const DarkSystemErrorIconInverted = '#660000';
export const DarkSystemErrorBackgroundDefault = '#f52e2e';
export const DarkSystemErrorBackgroundHover = '#f94a4a';
export const DarkSystemErrorBackgroundActive = '#fb6d6d';
export const DarkSystemErrorBackgroundWeakest = '#660000';
export const DarkSystemErrorBackgroundWeakestHover = '#a20000';
export const DarkSystemErrorBackgroundWeakestActive = '#fff1f1';
export const DarkSystemErrorBackgroundTransparent = '#F445451F';
export const DarkSystemErrorBorderDefault = '#e40707';
export const LighterQualitativeColors12Hue1 = '#473DD9';
export const LighterQualitativeColors12Hue2 = '#938CFF';
export const LighterQualitativeColors12Hue3 = '#36C4A5';
export const LighterQualitativeColors12Hue4 = '#ADF4E5';
export const LighterQualitativeColors12Hue5 = '#BDBCC8';
export const LighterQualitativeColors12Hue6 = '#56546D';
export const LighterQualitativeColors12Hue7 = '#56546D';
export const LighterQualitativeColors12Hue8 = '#FFCEB0';
export const LighterQualitativeColors12Hue9 = '#FF4781';
export const LighterQualitativeColors12Hue10 = '#FFB0C9';
export const LighterQualitativeColors12Hue11 = '#47B0FF';
export const LighterQualitativeColors12Hue12 = '#B0DDFF';
export const LighterQualitativeColors4Hue1 = '#473DD9';
export const LighterQualitativeColors4Hue2 = '#36C4A5';
export const LighterQualitativeColors4Hue3 = '#FF8C47';
export const LighterQualitativeColors4Hue4 = '#56546D';
export const LighterQualitativeColors21Hue1 = '#473DD9';
export const LighterQualitativeColors21Hue2 = '#36C4A5';
export const LighterQualitativeColors22Hue1 = '#36C4A5';
export const LighterQualitativeColors22Hue2 = '#473DD9';
export const LighterQualitativeColors3CfsScoreHue1 = '#473DD9';
export const LighterQualitativeColors3CfsScoreHue2 = '#36C4A5';
export const LighterQualitativeColors3CfsScoreHue3 = '#FFFFFF';
export const LighterQualitativeColors3CfsScore2Hue1 = '#473DD9';
export const LighterQualitativeColors3CfsScore2Hue2 = '#FF4781';
export const LighterQualitativeColors3CfsScore2Hue3 = '#FFFFFF';
export const LighterSequentialColors71Hue1 = '#D6D3FF';
export const LighterSequentialColors71Hue2 = '#B5B0FF';
export const LighterSequentialColors71Hue3 = '#938CFF';
export const LighterSequentialColors71Hue4 = '#7369FF';
export const LighterSequentialColors71Hue5 = '#5448FF';
export const LighterSequentialColors71Hue6 = '#473DD9';
export const LighterSequentialColors71Hue7 = '#3C33B5';
export const LighterSequentialColors72Hue1 = '#D1F9F0';
export const LighterSequentialColors72Hue2 = '#ADF4E5';
export const LighterSequentialColors72Hue3 = '#87EFD9';
export const LighterSequentialColors72Hue4 = '#62EBCD';
export const LighterSequentialColors72Hue5 = '#40E6C2';
export const LighterSequentialColors72Hue6 = '#36C4A5';
export const LighterSequentialColors72Hue7 = '#2DA38A';
export const LighterSequentialColors73Hue1 = '#FFE3D3';
export const LighterSequentialColors73Hue2 = '#FFCEB0';
export const LighterSequentialColors73Hue3 = '#FFB78B';
export const LighterSequentialColors73Hue4 = '#FFA168';
export const LighterSequentialColors73Hue5 = '#FF8C47';
export const LighterSequentialColors73Hue6 = '#D9773C';
export const LighterSequentialColors73Hue7 = '#B56332';
export const LighterSequentialColors74Hue1 = '#B56332';
export const LighterSequentialColors74Hue2 = '#89A4F7';
export const LighterSequentialColors74Hue3 = '#88B9F6';
export const LighterSequentialColors74Hue4 = '#8CCBF1';
export const LighterSequentialColors74Hue5 = '#98DAEB';
export const LighterSequentialColors74Hue6 = '#A8E7E7';
export const LighterSequentialColors74Hue7 = '#ADF4E5';
export const LighterDivergingColors91Hue1 = '#5448FF';
export const LighterDivergingColors91Hue2 = '#7369FF';
export const LighterDivergingColors91Hue3 = '#938CFF';
export const LighterDivergingColors91Hue4 = '#B5B0FF';
export const LighterDivergingColors91Hue5 = '#DADAE1';
export const LighterDivergingColors91Hue6 = '#ADF4E5';
export const LighterDivergingColors91Hue7 = '#87EFD9';
export const LighterDivergingColors91Hue8 = '#62EBCD';
export const LighterDivergingColors91Hue9 = '#40E6C2';
export const LighterDivergingColors92Hue1 = '#47B0FF';
export const LighterDivergingColors92Hue2 = '#68BEFF';
export const LighterDivergingColors92Hue3 = '#8BCDFF';
export const LighterDivergingColors92Hue4 = '#B0DDFF';
export const LighterDivergingColors92Hue5 = '#DADAE1';
export const LighterDivergingColors92Hue6 = '#FFCEB0';
export const LighterDivergingColors92Hue7 = '#FFB78B';
export const LighterDivergingColors92Hue8 = '#FFA168';
export const LighterDivergingColors92Hue9 = '#FF8C47';
export const DarkerQualitativeColors12Hue1 = '#1B3B5B';
export const DarkerQualitativeColors12Hue2 = '#738AA2';
export const DarkerQualitativeColors12Hue3 = '#265E78';
export const DarkerQualitativeColors12Hue4 = '#A5C1CE';
export const DarkerQualitativeColors12Hue5 = '#BDBCC8';
export const DarkerQualitativeColors12Hue6 = '#56546D';
export const DarkerQualitativeColors12Hue7 = '#E47225';
export const DarkerQualitativeColors12Hue8 = '#F3C2A1';
export const DarkerQualitativeColors12Hue9 = '#C52B20';
export const DarkerQualitativeColors12Hue10 = '#E6A49F';
export const DarkerQualitativeColors12Hue11 = '#074594';
export const DarkerQualitativeColors12Hue12 = '#94AFD1';
export const DarkerQualitativeColors4Hue1 = '#1B3B5B';
export const DarkerQualitativeColors4Hue2 = '#265E78';
export const DarkerQualitativeColors4Hue3 = '#E47225';
export const DarkerQualitativeColors4Hue4 = '#56546D';
export const DarkerQualitativeColors21Hue1 = '#1B3B5B';
export const DarkerQualitativeColors21Hue2 = '#265E78';
export const DarkerQualitativeColors22Hue1 = '#738AA2';
export const DarkerQualitativeColors22Hue2 = '#E47225';
export const DarkerSequentialColors71Hue1 = '#C9D2DB';
export const DarkerSequentialColors71Hue2 = '#9FAFBF';
export const DarkerSequentialColors71Hue3 = '#738AA2';
export const DarkerSequentialColors71Hue4 = '#486686';
export const DarkerSequentialColors71Hue5 = '#486686';
export const DarkerSequentialColors71Hue6 = '#1B3B5B';
export const DarkerSequentialColors71Hue7 = '#17314C';
export const DarkerSequentialColors72Hue1 = '#CDDCE4';
export const DarkerSequentialColors72Hue2 = '#A5C1CE';
export const DarkerSequentialColors72Hue3 = '#7BA4B7';
export const DarkerSequentialColors72Hue4 = '#5388A2';
export const DarkerSequentialColors72Hue5 = '#2D6E8D';
export const DarkerSequentialColors72Hue6 = '#265E78';
export const DarkerSequentialColors72Hue7 = '#204E64';
export const DarkerSequentialColors73Hue1 = '#F9DDCB';
export const DarkerSequentialColors73Hue2 = '#F3C2A1';
export const DarkerSequentialColors73Hue3 = '#EEA676';
export const DarkerSequentialColors73Hue4 = '#E98B4C';
export const DarkerSequentialColors73Hue5 = '#E47225';
export const DarkerSequentialColors73Hue6 = '#C2611F';
export const DarkerSequentialColors73Hue7 = '#A2511A';
export const DarkerSequentialColors74Hue1 = '#738AA2';
export const DarkerSequentialColors74Hue2 = '#89A4F7';
export const DarkerSequentialColors74Hue3 = '#88B9F6';
export const DarkerSequentialColors74Hue4 = '#8CCBF1';
export const DarkerSequentialColors74Hue5 = '#98DAEB';
export const DarkerSequentialColors74Hue6 = '#98DAEB';
export const DarkerSequentialColors74Hue7 = '#A5C1CE';
export const DarkerDivergingColors91Hue1 = '#20456B';
export const DarkerDivergingColors91Hue2 = '#486686';
export const DarkerDivergingColors91Hue3 = '#738AA2';
export const DarkerDivergingColors91Hue4 = '#9FAFBF';
export const DarkerDivergingColors91Hue5 = '#DADAE1';
export const DarkerDivergingColors91Hue6 = '#A5C1CE';
export const DarkerDivergingColors91Hue7 = '#7BA4B7';
export const DarkerDivergingColors91Hue8 = '#5388A2';
export const DarkerDivergingColors91Hue9 = '#2D6E8D';
export const DarkerDivergingColors92Hue1 = '#074594';
export const DarkerDivergingColors92Hue2 = '#3466A7';
export const DarkerDivergingColors92Hue3 = '#638ABC';
export const DarkerDivergingColors92Hue4 = '#94AFD1';
export const DarkerDivergingColors92Hue5 = '#DADAE1';
export const DarkerDivergingColors92Hue6 = '#F3C2A1';
export const DarkerDivergingColors92Hue7 = '#EEA676';
export const DarkerDivergingColors92Hue8 = '#E98B4C';
export const DarkerDivergingColors92Hue9 = '#E47225';
export const ShadowSm = '0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)';
export const ShadowMd = '0px 4px 8px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)';
export const ShadowLg = '0px 8px 12px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)';
export const ShadowXl =
  '0px 12px 16px 0px rgba(0, 0, 0, 0.12), 0px 4px 6px 0px rgba(0, 0, 0, 0.08)';
export const Shadow2xl =
  '0px 16px 20px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.08)';
export const ShadowInner = '0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset';
export const ShadowFocus = '0px 0px 0px 2px #005BD3';
export const ColorUxGray1 = '#f7fbff';
export const ColorUxGray2 = '#e4ebf4';
export const ColorUxGray3 = '#d6dfea';
export const ColorUxGray4 = '#cad3df';
export const ColorUxGray5 = '#bdc7d5';
export const ColorUxGray6 = '#a6b1c0';
export const ColorUxGray7 = '#909bab';
export const ColorUxGray8 = '#7b8696';
export const ColorUxGray9 = '#687280';
export const ColorUxGray10 = '#565f6b';
export const ColorUxGray11 = '#444c56';
export const ColorUxGray12 = '#333941';
export const ColorUxGray13 = '#22262c';
export const ColorUxGray14 = '#121417';
export const ColorUiGray1 = '#f1f3ff';
export const ColorUiGray2 = '#e4e6f4';
export const ColorUiGray3 = '#d6daea';
export const ColorUiGray4 = '#cacddf';
export const ColorUiGray5 = '#bdc1d5';
export const ColorUiGray6 = '#a6aac0';
export const ColorUiGray7 = '#9094ab';
export const ColorUiGray8 = '#7b8096';
export const ColorUiGray9 = '#686c80';
export const ColorUiGray10 = '#56596b';
export const ColorUiGray11 = '#444756';
export const ColorUiGray12 = '#333641';
export const ColorUiGray13 = '#22242c';
export const ColorUiGray14 = '#121317';
export const ColorBlue1 = '#f1f7ff';
export const ColorBlue2 = '#daeaff';
export const ColorBlue3 = '#c3defe';
export const ColorBlue4 = '#acd1fe';
export const ColorBlue5 = '#96c4fd';
export const ColorBlue6 = '#6dadfb';
export const ColorBlue7 = '#4a99f9';
export const ColorBlue8 = '#2e87f5';
export const ColorBlue9 = '#1778ef';
export const ColorBlue10 = '#076ae4';
export const ColorBlue11 = '#005dcf';
export const ColorBlue12 = '#0048a0';
export const ColorBlue13 = '#002d64';
export const ColorBlue14 = '#001630';
export const ColorLightBlue1 = '#f1fbff';
export const ColorLightBlue2 = '#daf4ff';
export const ColorLightBlue3 = '#c3ecfe';
export const ColorLightBlue4 = '#ace5fe';
export const ColorLightBlue5 = '#96defd';
export const ColorLightBlue6 = '#6dd1fb';
export const ColorLightBlue7 = '#4ac4f9';
export const ColorLightBlue8 = '#2eb9f5';
export const ColorLightBlue9 = '#17aeef';
export const ColorLightBlue10 = '#07a2e4';
export const ColorLightBlue11 = '#0091cf';
export const ColorLightBlue12 = '#0071a2';
export const ColorLightBlue13 = '#004766';
export const ColorLightBlue14 = '#002433';
export const ColorRed1 = '#fff1f1';
export const ColorRed2 = '#ffdada';
export const ColorRed3 = '#fec3c3';
export const ColorRed4 = '#feacac';
export const ColorRed5 = '#fd9696';
export const ColorRed6 = '#fb6d6d';
export const ColorRed7 = '#f94a4a';
export const ColorRed8 = '#f52e2e';
export const ColorRed9 = '#ef1717';
export const ColorRed10 = '#e40707';
export const ColorRed11 = '#cf0000';
export const ColorRed12 = '#a20000';
export const ColorRed13 = '#660000';
export const ColorRed14 = '#330000';
export const ColorYellow1 = '#fffcf1';
export const ColorYellow2 = '#fff7da';
export const ColorYellow3 = '#fef2c3';
export const ColorYellow4 = '#feedac';
export const ColorYellow5 = '#fde896';
export const ColorYellow6 = '#fbdf6d';
export const ColorYellow7 = '#f9d64a';
export const ColorYellow8 = '#f5cd2e';
export const ColorYellow9 = '#efc417';
export const ColorYellow10 = '#e4b807';
export const ColorYellow11 = '#cfa600';
export const ColorYellow12 = '#a28100';
export const ColorYellow13 = '#665100';
export const ColorYellow14 = '#332900';
export const ColorGreen1 = '#f1fff6';
export const ColorGreen2 = '#daffe7';
export const ColorGreen3 = '#c3fed9';
export const ColorGreen4 = '#acfeca';
export const ColorGreen5 = '#96fdbc';
export const ColorGreen6 = '#6dfba1';
export const ColorGreen7 = '#4af98a';
export const ColorGreen8 = '#2ef577';
export const ColorGreen9 = '#17ef66';
export const ColorGreen10 = '#07e458';
export const ColorGreen11 = '#00cf4c';
export const ColorGreen12 = '#00a23b';
export const ColorGreen13 = '#006625';
export const ColorGreen14 = '#003313';
export const ColorIndigo1 = '#f1f1ff';
export const ColorIndigo2 = '#e1e2ff';
export const ColorIndigo3 = '#d1d2fe';
export const ColorIndigo4 = '#c2c3fe';
export const ColorIndigo5 = '#b2b3fd';
export const ColorIndigo6 = '#9697fb';
export const ColorIndigo7 = '#7d7ff9';
export const ColorIndigo8 = '#686bf5';
export const ColorIndigo9 = '#575aef';
export const ColorIndigo10 = '#494be4';
export const ColorIndigo11 = '#3a3dcf';
export const ColorIndigo12 = '#292ba0';
export const ColorIndigo13 = '#181964';
export const ColorIndigo14 = '#0b0c30';
export const Radius2xs = '4px';
export const RadiusXs = '8px';
export const RadiusSm = '12px';
export const RadiusMd = '16px';
export const RadiusLg = '20px';
export const Radius2lg = '24px';
export const RadiusXl = '32px';
export const Radius2xl = '40px';
export const RadiusFull = '100%';
export const CustomSizeNone = '0px';
export const CustomSizeXxs = '2px';
export const CustomSize2xs = '4px';
export const CustomSizeXs = '8px';
export const CustomSizeSm = '12px';
export const CustomSize2md = '14px';
export const CustomSizeMd = '16px';
export const CustomSizeLg = '20px';
export const CustomSize2lg = '24px';
export const CustomSizeXl = '32px';
export const CustomSize2xl = '40px';
export const Spacing3xs = '2px';
export const Spacing2xs = '4px';
export const Spacing25xs = '6px';
export const SpacingXs = '8px';
export const SpacingSm = '12px';
export const SpacingMd = '16px';
export const SpacingLg = '24px';
export const SpacingXl = '32px';
export const Spacing2xl = '40px';
export const HeadingFontSize4xl = '48px';
export const HeadingFontSize3xl = '36px';
export const HeadingFontSize2xl = '30px';
export const HeadingFontSizeXl = '24px';
export const HeadingFontSizeLg = '20px';
export const HeadingFontSizeMd = '18px';
export const HeadingFontSizeSm = '16px';
export const HeadingLineHeight4xl = '48px';
export const HeadingLineHeight3xl = '40px';
export const HeadingLineHeight2xl = '36px';
export const HeadingLineHeightXl = '32px';
export const HeadingLineHeightLg = '28px';
export const HeadingLineHeightMd = '28px';
export const HeadingLineHeightSm = '24px';
export const BodyFontSizeLg = '16px';
export const BodyFontSizeMd = '14px';
export const BodyFontSizeSm = '12px';
export const BodyFontSizeXs = '10px';
export const BodyLineHeightLg = '24px';
export const BodyLineHeightMd = '20px';
export const BodyLineHeightSm = '16px';
export const BodyLineHeightXs = '14px';
export const InputActive = '#1D8792';
