import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientInitOptions } from '@react-keycloak/core/lib/types';
import { keycloakInstance } from './keycloack';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './router/Routes';

function App() {
  const keycloakProviderInitConfig: AuthClientInitOptions = {};
  return (
    <ReactKeycloakProvider authClient={keycloakInstance} initOptions={keycloakProviderInitConfig}>
      <AppRouter />
      <ToastContainer />
    </ReactKeycloakProvider>
  );
}

export default App;
