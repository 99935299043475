import React from 'react';

import { CoreChartBase } from '../CoreChartBase';
import { AreaChartViz } from './AreaChartViz';
import { AreaChartProps } from './AreaChart.types';

export const AreaChart = (props: AreaChartProps) => {
  return (
    <CoreChartBase
      {...{
        ...props,
        hasGridColumnsOffset: true,
        hasGridColumnsPadding: false,
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AreaChartViz
        {...{
          withPointsLabels: props.withPointsLabels,
          pointLabelFormatter: props.pointLabelFormatter,
        }}
      />
    </CoreChartBase>
  );
};
