import styled, { DefaultTheme, css } from 'styled-components';

import * as Tokens from '../../tokens/tokens';

const applyProps = (
  theme: DefaultTheme,
  variant?:
    | 'primary'
    | 'primary-gray'
    | 'secondary'
    | 'secondary-gray'
    | 'tertiary'
    | 'ghost'
    | 'error'
    | 'empty',
  $disabled?: boolean,
) => {
  switch (variant) {
    case 'primary': {
      return css`
        background-color: ${theme['primaryButton']['default']};
        color: ${theme['textPrimaryButton']};
        svg {
          path {
            fill: ${theme['textPrimaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['primaryButton']['hover']};
        }
        &:active {
          background-color: ${theme['primaryButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['primaryButton']['focus']};
        }

        ${() =>
          $disabled &&
          css`
            pointer-events: none;
            background-color: ${theme['primaryButton']['disabled']} !important;
            color: ${theme['textTertiaryButton']} !important;
            svg {
              path {
                fill: ${theme['textTertiaryButton']} !important;
              }
            }
          `};
      `;
    }
    case 'primary-gray': {
      return css`
        background-color: ${theme['primaryGrayButton']['default']};
        color: ${theme['textPrimaryButton']};
        svg {
          path {
            fill: ${theme['textPrimaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['primaryGrayButton']['hover']};
        }
        &:active {
          background-color: ${theme['primaryGrayButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['primaryGrayButton']['focus']};
        }

        ${() =>
          $disabled &&
          css`
            opacity: 60%;
            pointer-events: none;
            background-color: ${theme['secondaryButton']['disabled']} !important;
            color: ${theme['textSecondaryGrayButton']} !important;
            svg {
              path {
                fill: ${theme['textSecondaryGrayButton']} !important;
              }
            }
          `};
      `;
    }
    case 'secondary': {
      return css`
        background-color: ${theme['secondaryButton']['default']};
        color: ${theme['textSecondaryButton']};
        svg {
          path {
            fill: ${theme['textSecondaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['secondaryButton']['hover']};
        }
        &:active {
          background-color: ${theme['secondaryButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['secondaryButton']['focus']};
        }

        ${() =>
          $disabled &&
          css`
            opacity: 60%;
            pointer-events: none;
            background-color: ${theme['secondaryButton']['disabled']} !important;
            color: ${theme['textSecondaryGrayButton']} !important;
            svg {
              path {
                fill: ${theme['textSecondaryGrayButton']} !important;
              }
            }
          `};
      `;
    }
    case 'secondary-gray': {
      return css`
        background-color: ${theme['secondaryGrayButton']['default']};
        color: ${theme['textSecondaryGrayButton']};
        svg {
          path {
            fill: ${theme['textSecondaryGrayButton']};
          }
        }

        &:hover {
          background-color: ${theme['secondaryGrayButton']['hover']};
        }
        &:active {
          background-color: ${theme['secondaryGrayButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['secondaryGrayButton']['focus']};
        }

        ${() =>
          $disabled &&
          css`
            opacity: 60%;
            pointer-events: none;
            background-color: ${theme['secondaryButton']['disabled']} !important;
            color: ${theme['textSecondaryGrayButton']} !important;
            svg {
              path {
                fill: ${theme['textSecondaryGrayButton']} !important;
              }
            }
          `};
      `;
    }
    case 'tertiary': {
      return css`
        background-color: ${theme['tertiaryButton']['default']};
        color: ${theme['textTertiaryButton']};
        border-color: ${theme['borderTertiaryButton']};
        svg {
          path {
            fill: ${theme['textTertiaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['tertiaryButton']['hover']};
        }
        &:active {
          background-color: ${theme['tertiaryButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['tertiaryButton']['focus']};
        }
        ${() =>
          $disabled &&
          css`
            pointer-events: none;
            background-color: ${theme['tertiaryButton']['disabled']} !important;
            border-color: ${theme['borderTertiaryButton']} !important;
            color: ${theme['textTertiaryDisabledButton']} !important;
            svg {
              path {
                fill: ${theme['textTertiaryDisabledButton']} !important;
              }
            }
          `};
      `;
    }
    case 'ghost': {
      return css`
        background-color: ${theme['ghostButton']['default']};
        color: ${theme['textTertiaryButton']};
        svg {
          path {
            fill: ${theme['textTertiaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['ghostButton']['hover']};
        }
        &:active {
          background-color: ${theme['ghostButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['ghostButton']['focus']};
        }

        ${() =>
          $disabled &&
          css`
            pointer-events: none;
            background-color: ${theme['tertiaryButton']['disabled']} !important;
            color: ${theme['textTertiaryDisabledButton']} !important;
            svg {
              path {
                fill: ${theme['textTertiaryDisabledButton']} !important;
              }
            }
          `};
      `;
    }
    case 'error': {
      return css`
        background-color: ${theme['errorButton']['default']};
        color: ${theme['textPrimaryButton']};
        svg {
          path {
            fill: ${theme['textPrimaryButton']};
          }
        }

        &:hover {
          background-color: ${theme['errorButton']['hover']};
        }
        &:active {
          background-color: ${theme['errorButton']['active']} !important;
        }
        &:focus {
          background-color: ${theme['errorButton']['focus']};
        }
      `;
    }
    case 'empty': {
      return css`
        background-color: ${theme['primaryButton']['empty']};
      `;
    }
  }
};

const padding = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return {
        px: Tokens.ButtonPaddingSmPx,
        py: Tokens.ButtonPaddingSmPy,
      };
    }
    case 'md': {
      return {
        px: Tokens.ButtonPaddingMdPx,
        py: Tokens.ButtonPaddingMdPy,
      };
    }
    case 'lg': {
      return {
        px: Tokens.ButtonPaddingLgPx,
        py: Tokens.ButtonPaddingLgPy,
      };
    }
    default: {
      return {
        px: Tokens.ButtonPaddingLgPx,
        py: Tokens.ButtonPaddingLgPy,
      };
    }
  }
};

const font = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return {
        size: Tokens.ButtonFontSmSize,
        lineHeight: Tokens.ButtonFontSmLineHeight,
      };
    }
    case 'md': {
      return {
        size: Tokens.ButtonFontMdSize,
        lineHeight: Tokens.ButtonFontMdLineHeight,
      };
    }
    case 'lg': {
      return {
        size: Tokens.ButtonFontLgSize,
        lineHeight: Tokens.ButtonFontLgLineHeight,
      };
    }
    default: {
      return {
        size: Tokens.ButtonFontLgSize,
        lineHeight: Tokens.ButtonFontLgLineHeight,
      };
    }
  }
};

const icon = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return Tokens.CustomSizeMd;
    }
    case 'md':
    case 'lg': {
      return Tokens.CustomSizeLg;
    }
    default: {
      return Tokens.CustomSizeLg;
    }
  }
};

export const CustomButton = styled.button<{
  $size: 'lg' | 'md' | 'sm';
  $variant:
    | 'primary'
    | 'primary-gray'
    | 'secondary'
    | 'secondary-gray'
    | 'tertiary'
    | 'ghost'
    | 'error'
    | 'empty';
  $disabled?: boolean;
  $iconOnly?: boolean;
}>`
  font-family: 'Inter';
  font-size: ${({ $size }) => font($size).size};
  line-height: ${({ $size }) => font($size).lineHeight};
  font-weight: 600;
  border-radius: ${Tokens.ButtonRadiusDefault};
  border: 1px solid transparent;

  &:focus {
    box-shadow: ${Tokens.ShadowFocus};
    border-color: ${({ theme }) => theme['borderDefaultButton']};
  }

  &:hover {
    cursor: pointer;
  }

  padding: ${({ $size }) => padding($size).py + ' ' + padding($size).px};

  ${({ $iconOnly, $size }) =>
    $iconOnly &&
    css`
      padding: ${padding($size).py};
    `};

  svg {
    height: ${({ $size }) => icon($size)}px;
    width: ${({ $size }) => icon($size)}px;
  }

  ${({ theme, $variant, $disabled }) => applyProps(theme, $variant, $disabled)};
`;

export const IconWrapper = styled.div<{
  $small?: boolean;
  $variant:
    | 'primary'
    | 'primary-gray'
    | 'secondary'
    | 'secondary-gray'
    | 'tertiary'
    | 'ghost'
    | 'error'
    | 'empty';
}>`
  display: flex;
  gap: ${({ $small }) => ($small ? Tokens.Spacing25xs : Tokens.SpacingXs)};
  opacity: ${({ $variant }) => ($variant === 'empty' ? 0 : 1)};
  align-items: center;
`;
