import { Badge, Dot } from './styles';
import { StatusBadgeProps } from './types';

const StatusBadge = ({
  variant = 'default',
  size = 'lg',
  label,
  onClick,
  icon,
  dot = false,
  muted = false,
}: StatusBadgeProps) => {
  const isIconPath = typeof icon === 'string';

  return (
    <Badge $variant={variant} $size={size} onClick={onClick} $dot={dot} $muted={muted}>
      {!dot ? (
        isIconPath ? (
          <img src={icon} alt="dimension-icon" />
        ) : (
          icon
        )
      ) : (
        <Dot $variant={variant} $size={size} />
      )}
      {label}
    </Badge>
  );
};

export default StatusBadge;
