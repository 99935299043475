import Table from '@components/Table';
import { Card } from '@components/Card';
import { SectionTitle } from '@components/SectionTitle';
import { LocalDemographicsMap } from '@components/LocalDemographicsMap';

import FeaturesIcon from '@assets/icons/Features';

import {
  FormatetedTableData,
  FormatetedTableDataRow,
  LocalDemographicsProps,
  TableData,
  TableDataItem,
  TableDataRow,
} from './LocalDemographics.types';
import { ColumnConfig } from '@components/Table/types';

const LocalDemographics = ({ mapData, tableData }: LocalDemographicsProps) => {
  const columns: ColumnConfig<TableDataRow>[] = [
    {
      sortable: true,
      label: 'Attribute',
      accessor: 'attribute',
      widthClass: 'w-1/2',
    },
    {
      sortable: true,
      label: '1 Mile',
      accessor: 'mile1',
    },
    {
      sortable: true,
      label: '3 Mile',
      accessor: 'mile3',
    },
    {
      sortable: true,
      label: '5 Mile',
      accessor: 'mile5',
    },
  ];

  const formatData = (data: TableData) => {
    const formattedData: FormatetedTableData = data.map((dataItem: TableDataItem) => {
      if (Array.isArray(dataItem[0])) {
        const rowDataArray = dataItem
          .map((rowData) => {
            const [attribute, mile1, mile3, mile5] = rowData as TableDataItem;

            return {
              attribute,
              mile1,
              mile3,
              mile5,
            } as FormatetedTableDataRow;
          })
          .filter((item) => !!item);

        return rowDataArray;
      } else {
        const [attribute, mile1, mile3, mile5] = dataItem as TableDataItem;

        return {
          attribute,
          mile1,
          mile3,
          mile5,
        } as FormatetedTableDataRow;
      }
    });

    return formattedData;
  };

  return (
    <section>
      <SectionTitle className="mb-4" title="Local Demographics" icon={<FeaturesIcon />} />

      <div className="flex gap-2">
        <Card className="flex-1">
          <Table data={formatData(tableData)} columns={columns} initialSortField={'attribute'} />
        </Card>

        <div className="min-w-[400px] min-h-[200px]">
          <LocalDemographicsMap data={mapData} />
        </div>
      </div>
    </section>
  );
};

export default LocalDemographics;
