const CompareIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.333 3.333h-5v-1a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h5v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1Zm-12 4.5h3.085l-1.29 1.295.705.705 2.5-2.5-2.5-2.5-.705.705 1.29 1.295H2.333v-4.5h6v10h-6v-4.5Zm6 6.5v-1a1 1 0 0 0 1-1v-8h5v4.5h-3.085l1.29-1.295-.705-.705-2.5 2.5 2.5 2.5.705-.705-1.29-1.295h3.085v4.5h-6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default CompareIcon;
