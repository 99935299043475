import { PropsWithChildren } from 'react';

interface CardProps {
  className?: string;
}

export const Card = ({ className, children }: PropsWithChildren<CardProps>) => {
  return (
    <div className={`p-6 bg-light-neutralBackground rounded-2xl ${className ?? ''}`}>
      {children}
    </div>
  );
};
