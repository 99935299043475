export const DotFull = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.00201" r="3.28125" fill="#7B8096" />
    </svg>
  );
};

export const DotEmpty = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.64563C4.90609 5.64563 5.64062 4.9111 5.64062 4.005C5.64062 3.09891 4.90609 2.36438 4 2.36438C3.09391 2.36438 2.35938 3.09891 2.35938 4.005C2.35938 4.9111 3.09391 5.64563 4 5.64563ZM4 7.28625C5.81218 7.28625 7.28125 5.81719 7.28125 4.005C7.28125 2.19282 5.81218 0.723755 4 0.723755C2.18782 0.723755 0.71875 2.19282 0.71875 4.005C0.71875 5.81719 2.18782 7.28625 4 7.28625Z"
        fill="#7B8096"
      />
    </svg>
  );
};
