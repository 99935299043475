import styled, { css } from 'styled-components';

import * as Tokens from '../../tokens/tokens';

const padding = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return {
        px: Tokens.BadgeInteractivePaddingSmPx,
        py: Tokens.BadgeInteractivePaddingSmPy,
      };
    }
    case 'md': {
      return {
        px: Tokens.BadgeInteractivePaddingMdPx,
        py: Tokens.BadgeInteractivePaddingMdPy,
      };
    }
    case 'lg': {
      return {
        px: Tokens.BadgeInteractivePaddingLgPx,
        py: Tokens.BadgeInteractivePaddingLgPy,
      };
    }
    default: {
      return {
        px: Tokens.BadgeInteractivePaddingLgPx,
        py: Tokens.BadgeInteractivePaddingLgPy,
      };
    }
  }
};

const font = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return {
        size: Tokens.BadgeInteractiveFontSmSize,
        lineHeight: Tokens.BadgeInteractiveFontSmLineHeight,
      };
    }
    case 'md': {
      return {
        size: Tokens.BadgeInteractiveFontMdSize,
        lineHeight: Tokens.BadgeInteractiveFontMdLineHeight,
      };
    }
    case 'lg': {
      return {
        size: Tokens.BadgeInteractiveFontLgSize,
        lineHeight: Tokens.BadgeInteractiveFontLgLineHeight,
      };
    }
    default: {
      return {
        size: Tokens.BadgeInteractiveFontLgSize,
        lineHeight: Tokens.BadgeInteractiveFontLgLineHeight,
      };
    }
  }
};

export const Wrapper = styled.div<{
  $variant: 'default' | 'empty';
}>`
  position: relative;
  background: ${({ theme, $variant }) =>
    $variant === 'empty' ? theme['badge']['empty'] : 'transparent'};
  border-radius: ${Tokens.Radius2lg};
`;

export const Hidden = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0.5px;
  height: 1px;
  top: 0;

  &:focus + label {
    box-shadow: ${Tokens.ShadowFocus};
  }
`;

export const InteractiveBadge = styled.label<{
  $selected?: boolean;
  $disabled?: boolean;
  $size: 'lg' | 'md' | 'sm';
  $variant: 'default' | 'empty';
}>`
  display: flex;
  align-items: center;
  border-radius: ${Tokens.Radius2lg};
  gap: 2px;
  font-size: ${({ $size }) => font($size).size};
  line-height: ${({ $size }) => font($size).lineHeight};
  background: ${({ theme }) => theme['badge']['default']};
  color: ${({ theme }) => theme['badgeText']};
  opacity: ${({ $variant }) => ($variant === 'empty' ? 0 : 1)};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme['badge']['hover']};
  }
  &:active {
    background: ${({ theme }) => theme['badge']['pressed']};
  }
  &:focus {
    background: ${({ theme }) => theme['badge']['focus']};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background: ${({ theme }) => theme['badge']['selected']};
      color: ${({ theme }) => theme['badgeSelectedText']};

      svg {
        path {
          fill: ${({ theme }) => theme['badgeSelectedText']} !important;
        }
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 60%;
      cursor: not-allowed;
      ${({ theme }) => theme['badge']['disabled']};
    `}

  svg, img {
    width: ${Tokens.CustomSize2md};
    height: ${Tokens.CustomSize2md};
  }

  padding: ${({ $size }) => padding($size).py + ' ' + padding($size).px};
`;

export const Badge = styled.label<{
  $size: 'lg' | 'sm';
  $variant: 'default' | 'error' | 'success' | 'warning' | 'info';
  $dot?: boolean;
  $muted?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Tokens.RadiusXl};
  width: fit-content;
  gap: 2px;
  font-size: ${({ $size }) => font($size).size};
  line-height: ${({ $size }) => font($size).lineHeight};
  background: ${({ theme }) => theme['badge']['default']};

  color: ${({ theme, $variant }) =>
    $variant === 'default' || $variant === 'error'
      ? theme['statusBadgeText']['inverted']
      : theme['statusBadgeText']['default']};

  background: ${({ theme, $variant }) => theme['statusBadgeBackgroundSaturated'][$variant]};

  img,
  svg {
    width: ${Tokens.CustomSizeSm};
    height: ${Tokens.CustomSizeSm};

    path {
      fill: ${({ theme, $variant }) =>
        $variant === 'default' || $variant === 'error'
          ? theme['statusBadgeText']['inverted']
          : theme['statusBadgeText']['default']};
    }
  }

  padding: ${({ $size }) => padding($size).py + ' ' + padding($size).px};
  padding-right: ${({ $size }) => `${Number(padding($size).px.replace('px', '')) + 2}px`};

  ${({ $dot }) =>
    $dot &&
    css`
      background: transparent;
      color: ${({ theme }) => theme['statusBadgeText']['default']};
    `}

  ${({ $variant, theme, $muted }) =>
    $muted &&
    css`
      background: ${theme['statusBadgeBackgroundMuted'][$variant]};
      color: ${theme['statusBadgeText'][$variant]};

      svg {
        path {
          fill: ${theme['statusBadgeText'][$variant]};
        }
      }
    `}
`;

export const Dot = styled.div<{
  $size: 'lg' | 'sm';
  $variant: 'default' | 'error' | 'success' | 'warning' | 'info';
}>`
  width: ${({ $size }) => ($size === 'lg' ? 7.5 : 6.56)}px;
  height: ${({ $size }) => ($size === 'lg' ? 7.5 : 6.56)}px;

  background: ${({ theme, $variant }) => theme['statusBadgeBackgroundSaturated'][$variant]};
  border-radius: 100%;
`;
