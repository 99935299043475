import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import AxiosFacade from './AxiosFacade';
import { AxiosRequestConfig } from 'axios';

const useAxios = (options?: AxiosRequestConfig) => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  console.log(token)
  // if (token) {
  //   keycloak.updateToken(3600).then(function (refreshed) {
  //     if (refreshed) {
  //       console.info('Token was successfully refreshed');
  //     }
  //   });
  // }



  return useMemo(() => {
    const axiosFacade = new AxiosFacade({
      ...options,
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        ...(options?.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    });

    axiosFacade.setResponseInterceptor(
      (response) => response,
      async (error) => {
        // if(keycloak.onReady(() => {
        //
        // }))
        if (error.response?.status === 401) {
          // await keycloak.login();
        }
      },
    );

    return axiosFacade;
  }, [keycloak, token, options]);
};

export default useAxios;
