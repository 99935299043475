import { ModalLayout } from './Layout';
import { useState } from 'react';
import { ModalIcon } from '@assets/icons/ModalIcon';
import { CreateReportModal } from './modals/CreateReportModal';
import { LockBodyScroll } from '@components/LockBodyScroll';

interface Props {
  modalOpen: boolean;
  closeModal: () => void;
  isReportsPage?: boolean;
  clientId?: string;
}

export const Modal = (props: Props): JSX.Element => {
  const { modalOpen, closeModal, isReportsPage, clientId } = props;
  const [modalProps, setModalProps] = useState({ title: 'Create Report', icon: ModalIcon });

  const handleClose = () => {
    closeModal();
  };

  return (
    <ModalLayout
      modalOpen={modalOpen}
      closeModal={handleClose}
      title={modalProps.title}
      Icon={modalProps.icon}
    >
      <LockBodyScroll />
      <CreateReportModal
        isReportsPage={isReportsPage}
        clientId={clientId}
        setModalProps={setModalProps}
      />
    </ModalLayout>
  );
};
