const BarChartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="url(#a)"
        d="M1.25 13.75c0-.69.56-1.25 1.25-1.25H5c.69 0 1.25.56 1.25 1.25v3.75c0 .69-.56 1.25-1.25 1.25H2.5c-.69 0-1.25-.56-1.25-1.25v-3.75Z"
      />
      <path
        fill="url(#b)"
        d="M7.5 8.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25v8.75c0 .69-.56 1.25-1.25 1.25h-2.5c-.69 0-1.25-.56-1.25-1.25V8.75Z"
      />
      <path
        fill="url(#c)"
        d="M13.75 2.5c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25v15c0 .69-.56 1.25-1.25 1.25H15c-.69 0-1.25-.56-1.25-1.25v-15Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="8.713"
          x2="8.713"
          y1="1.25"
          y2="18.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="8.713"
          x2="8.713"
          y1="1.25"
          y2="18.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="8.713"
          x2="8.713"
          y1="1.25"
          y2="18.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BarChartIcon;
