import { ColumnConfig } from '@components/Table/types';
import { UserData } from '../types/types';
import { formatDate } from '@utils/formatData';
import Eyes from '@assets/icons/Eyes';
import { replaceUSwithZipcode } from '@utils/replaceUSwithZipcode';

export const columns: ColumnConfig<UserData>[] = [
  { label: 'Entity name', accessor: 'entityName', sortable: false, widthClass: 'w-1/5' },
  { label: 'Property Location', accessor: 'propertyLocation', sortable: false },
  { label: 'Report Creation Date', accessor: 'createdAt', sortable: false, widthClass: 'w-1/5' },
  { label: 'Action', accessor: 'action', sortable: false, widthClass: 'w-1/8' },
];

export const processedData = (data: UserData[], navigate: (url: string) => void) =>
  data.map((item) => ({
    ...item,
    entityName: item.entityName || 'NOT SET',
    createdAt: formatDate(item.createdAt),
    updatedAt: formatDate(item.updatedAt),
    propertyLocation: replaceUSwithZipcode(item.propertyLocation, item.propertyZipcode) || 'NOT SET',
    action: <Eyes onClick={() => navigate(`/reports/${item._id}`)} />,
  }));
