import styled from 'styled-components';

export const PrintContentWrapperStyled = styled.div<{
  $width: string;
  $height: string;
}>`
  @media print {
    @page {
      size: ${({ $width, $height }) => `${$width} ${$height}`};
      margin: 0;
    }

    [class*='shadow'] {
      box-shadow: none;
    }
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;
