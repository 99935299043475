const EmptyTable = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 9.002V10.002H5.293L1.5 13.795L2.207 14.502L6 10.709V13.502H7V9.002H2.5Z"
        fill="#004766"
      />
      <path
        d="M13.5 1.502H2.5C2.23478 1.502 1.98043 1.60736 1.79289 1.79489C1.60536 1.98243 1.5 2.23678 1.5 2.502V7.502H2.5V5.502H7.5V7.502H8.5V5.502H13.5V9.002H8.5V10.002H13.5V13.502H8.5V14.502H13.5C13.7652 14.502 14.0196 14.3966 14.2071 14.2091C14.3946 14.0216 14.5 13.7672 14.5 13.502V2.502C14.5 2.23678 14.3946 1.98243 14.2071 1.79489C14.0196 1.60736 13.7652 1.502 13.5 1.502ZM13.5 4.502H2.5V2.502H13.5V4.502Z"
        fill="#004766"
      />
    </svg>
  );
};

export default EmptyTable;
