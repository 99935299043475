const EventsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <g fill="#111" clipPath="url(#a)">
        <path d="M13.333 7.333h-1v1h1a1.502 1.502 0 0 1 1.5 1.5v2h1v-2a2.503 2.503 0 0 0-2.5-2.5ZM12.333 2.333a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-1a2.5 2.5 0 1 0 0 5.001 2.5 2.5 0 0 0 0-5ZM11.833 15.333h-1v-1a1.502 1.502 0 0 0-1.5-1.5h-2a1.501 1.501 0 0 0-1.5 1.5v1h-1v-1a2.503 2.503 0 0 1 2.5-2.5h2a2.503 2.503 0 0 1 2.5 2.5v1ZM8.333 6.833a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM4.333 7.333h-1a2.503 2.503 0 0 0-2.5 2.5v2h1v-2a1.502 1.502 0 0 1 1.5-1.5h1v-1ZM4.333 2.333a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.333.333h16v16h-16z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EventsIcon;
