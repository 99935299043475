type PercentageBarProps = {
  accentColor?: string;
  percentage: number;
  label: string;
};

const PercentageBar = ({ accentColor, percentage, label }: PercentageBarProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-end justify-between">
        <span className="text-xs font-normal leading-3 text-light-neutralText">{label}</span>

        <span className="text-base font-semibold leading-5 text-light-neutralText tracking-[1%]">
          {percentage}
          <span className="text-[12px] font-normal leading-none mt-3 ml-[2px]">%</span>
        </span>
      </div>

      <div className="flex items-center h-3 rounded bg-light-neutralBorder">
        <div className="relative" style={{ width: `${percentage}%` }}>
          <div
            className={`${accentColor ?? 'bg-light-dataViz-qualitative_12-11'} m-[2px] rounded-l h-2 relative flex items-center after:absolute after:right-0 after:h-4 after:w-[2px] after:rounded-full after:bg-inherit`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PercentageBar;
