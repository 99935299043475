import { Card } from '@components/Card';
import { ShapValueLegend } from '@components/ShapValueLegend';

import { AnalyticsCardProps } from './AnalyticsCard.types';
import { ScatterPlot } from '@components/Charts/ScatterPlot';

const xAxisTickFormatter = (value: number): string =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);

const scatterPlotConfig = {
  height: 55,

  leftAxisLabel: '',
  bottomAxisLabel: '',
  topAxisLabel: '',
  rightAxisLabel: '',

  yAxisNumTicks: 0.1,

  showTopAxis: false,
  showRightAxis: false,
  showBottomAxis: false,
  showLeftAxis: false,

  showVerticalAxesLine: false,
  showVerticalAxesTicks: false,
  showHorizontalAxesTicks: false,

  widerLeftTrick: false,
  widerRightTrick: false,

  hasGridColumnsPadding: false,
  hasGridColumnsOffset: false,

  roundScale: true,
  niceScale: true,

  minYScaleValue: -2,
  maxYScaleValue: 2,

  withTooltip: true,

  hideZero: true,

  bottomAxisTickFormatter: xAxisTickFormatter,
};

export const AnalyticsCard = ({
  data,
  dataKeys,
  title,
  minXScaleValue = 0,
  maxXScaleValue,
  bottomAxisLabel = ' ',
}: AnalyticsCardProps) => {
  return (
    <Card className="flex flex-col gap-4">
      <div className="relative flex items-start justify-between gap-8">
        <h3 className="text-2xl leading-none tracking-tight" title={title}>
          {title}
        </h3>

        <div className="w-52 shrink-0">
          <ShapValueLegend />
        </div>
      </div>

      {data && dataKeys ? (
        <div className="min-h-[280px] p-6 pr-8 rounded-2xl bg-white overflow-hidden animate-fadein [animation-duration:0.4s]">
          <div className="flex gap-2">
            <div className="flex items-center w-4 overflow-hidden">
              <span className="relative origin-top-left -rotate-90 translate-y-full aspect-square text-[12px] text-light-neutralTextWeak">
                Chains
              </span>
            </div>

            <div className="flex flex-col w-full gap-2 grow">
              <div className="w-full h-[55px] flex items-start gap-2">
                <div className="flex items-start h-[55px] text-[11px] cursor-default w-16 text-light-neutralTextWeak text-balance">
                  {data[2].chain}
                </div>

                <div className="grow">
                  <ScatterPlot
                    {...{
                      ...scatterPlotConfig,
                      data: data[2].stats,
                      dataKeys: dataKeys,
                      minXScaleValue: minXScaleValue,
                      maxXScaleValue: maxXScaleValue,
                    }}
                  />
                </div>
              </div>

              <div className="w-full h-[55px] flex items-start gap-2">
                <div className="flex items-start h-[55px] text-[11px] cursor-default w-16 text-light-neutralTextWeak text-balance">
                  {data[1].chain}
                </div>

                <div className="grow">
                  <ScatterPlot
                    {...{
                      ...scatterPlotConfig,
                      data: data[1].stats,
                      dataKeys: dataKeys,
                      minXScaleValue: minXScaleValue,
                      maxXScaleValue: maxXScaleValue,
                    }}
                  />
                </div>
              </div>

              <div className="w-full h-[55px] flex items-start gap-2">
                <div className="flex items-start h-[55px] text-[11px] cursor-default w-16 text-light-neutralTextWeak text-balance">
                  {data[0].chain}
                </div>

                <div className="grow">
                  <ScatterPlot
                    {...{
                      ...scatterPlotConfig,
                      data: data[0].stats,
                      dataKeys: dataKeys,
                      minXScaleValue: minXScaleValue,
                      maxXScaleValue: maxXScaleValue,
                      height: 104,
                      bottomAxisLabel: bottomAxisLabel ?? ' ',
                      showBottomAxis: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Card>
  );
};
