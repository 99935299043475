import { ArrowRight } from '@assets/icons/ArrowRight';
interface Props {
  values: Record<string, any>;
}
export const Step3 = ({ values }: Props) => {
  return (
    <>
      <span className="w-full border-t border-dark-neutralBorder"></span>
      <a
        href={`/reports/${values.reportID}`}
        className="ml-auto flex gap-2 items-center bg-[#0E717B14] py-2 px-3 text-sm text-light-primaryButton-default font-semibold rounded-lg w-max text-nowrap"
      >
        View Report
        <ArrowRight />
      </a>
    </>
  );
};
