import DetailBlock from '@components/Detail';
import { Card } from '@components/Card';

import { ChainDetailsProps } from './ChainDetails.types';

export const ChainDetails = ({ data }: ChainDetailsProps) => {
  const details = Object.entries(data);

  return (
    <Card className="flex flex-col gap-4">
      <h3 className="text-2xl leading-none tracking-tight">Chain Details</h3>

      <div className="grid grid-cols-2 gap-[2px]">
        {details.map(([key, value]) => (
          <DetailBlock key={key} title={value} description={key} />
        ))}
      </div>
    </Card>
  );
};
