export const replaceUSwithZipcode = (address: string, zipcode: number) => {
  let formatedAddress = '';

  if (address) {
    formatedAddress = address.replace(', United States', '');
  }

  if (zipcode) {
    formatedAddress = formatedAddress.replace(`, ${zipcode}`, '');

    formatedAddress += ', ' + zipcode;
  }

  return formatedAddress;
};
