import styled, { keyframes } from 'styled-components';

import * as Tokens from '../../tokens/tokens';

const icon = (size?: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'sm': {
      return Tokens.CustomSizeMd;
    }
    case 'md': {
      return Tokens.CustomSize2lg;
    }
    case 'lg': {
      return Tokens.CustomSize2xl;
    }
    default: {
      return Tokens.CustomSize2xl;
    }
  }
};

const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;
export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const LoadingSpinner = styled.div<{ $size: 'lg' | 'md' | 'sm' }>`
  position: relative;
  border-radius: 50%;
  animation: 1.5s ${Spinner} linear infinite;
  width: ${({ $size }) => icon($size)};
  height: ${({ $size }) => icon($size)};
  border: 3px solid ${({ theme }) => theme['loaderBackground']};
  border-bottom: 3px solid ${({ theme }) => theme['loaderAccent']};
`;
