import { useRef, useEffect, useState } from 'react';
import useMapbox from '@services/mapbox/useMapbox';

import milesToMapPixels from '@utils/milesToMapPixels';

import RadiusIndicatorOneMile from '@assets/icons/RadiusIndicatorOneMile';
import RadiusIndicatorThreeMiles from '@assets/icons/RadiusIndicatorThreeMiles';
import RadiusIndicatorFiveMiles from '@assets/icons/RadiusIndicatorFiveMiles';

import { LocalDemographicsMapProps } from './LocalDemographicsMap.types';
import { MapContainerStyled } from './LocalDemographicsMap.styles';

export const LocalDemographicsMap = ({ data }: LocalDemographicsMapProps) => {
  const mapboxgl = useMapbox();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);

  const [lng, setLng] = useState<number>(data.longitude as number);
  const [lat, setLat] = useState<number>(data.latitude as number);
  const [zoom, setZoom] = useState<number>(10);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLDivElement,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
      preserveDrawingBuffer: true,
      cooperativeGestures: true,
    });

    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      'bottom-right',
    );

    map.current.on('load', () => {
      map.current.resize();

      const markerEl = document.createElement('div');
      markerEl.className = 'marker';

      const popup = new mapboxgl.Popup({
        anchor: 'top',
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        offset: 15,
      }).setHTML(
        `<div class="max-w-40 text-xs text-light-neutralText text-ellipsis overflow-hidden truncate">${data.name}</div>`,
      );

      new mapboxgl.Marker(markerEl)
        .setLngLat([lng, lat])
        .setPopup(popup)
        .addTo(map.current)
        .togglePopup();

      map.current.addSource('5-miles-radius', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          properties: {
            radius: milesToMapPixels(lat, 5, 22),
          },
        },
      });

      map.current.addSource('3-miles-radius', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          properties: {
            radius: milesToMapPixels(lat, 3, 22),
          },
        },
      });

      map.current.addSource('1-mile-radius', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          properties: {
            radius: milesToMapPixels(lat, 1, 22),
          },
        },
      });

      map.current.addLayer({
        id: '5-miles-radius',
        type: 'circle',
        source: '5-miles-radius',
        paint: {
          'circle-radius': {
            stops: [
              [0, 0],
              [22, milesToMapPixels(lat, 5, 22)],
            ],
            base: 2,
          },
          'circle-color': '#47b0ff',
          'circle-opacity': 0.3,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
          'circle-stroke-opacity': 1,
        },
      });

      map.current.addLayer({
        id: '3-miles-radius',
        type: 'circle',
        source: '3-miles-radius',
        paint: {
          'circle-radius': {
            stops: [
              [0, 0],
              [22, milesToMapPixels(lat, 3, 22)],
            ],
            base: 2,
          },
          'circle-color': '#47b0ff',
          'circle-opacity': 0.2,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
          'circle-stroke-opacity': 1,
        },
      });

      map.current.addLayer({
        id: '1-mile-radius',
        type: 'circle',
        source: '1-mile-radius',
        paint: {
          'circle-radius': {
            stops: [
              [0, 0],
              [22, milesToMapPixels(lat, 1, 22)],
            ],
            base: 2,
          },
          'circle-color': '#47b0ff',
          'circle-opacity': 0.1,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
          'circle-stroke-opacity': 1,
        },
      });
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    return () => map.current.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full border border-solid rounded-2xl h-full border-[#47b0ff] overflow-hidden">
      <MapContainerStyled
        ref={mapContainer}
        className="h-full animate-fadein [animation-duration:.7s]"
      ></MapContainerStyled>

      <div className="absolute p-2 bg-white border rounded-lg left-2 bottom-2 border-light-neutralBorder">
        <div className="text-xs font-medium leading-none">Population</div>

        <div className="flex flex-col items-start justify-start gap-1 mt-2">
          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-[2px] bg-white rounded-full shadow min-w-4 aspect-square">
              <RadiusIndicatorOneMile />
            </div>
            <div className="text-xs text-light-neutralTextWeak">
              1 Mile ({data.populationOneMile})
            </div>
          </div>

          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-[2px] bg-white rounded-full shadow min-w-4 aspect-square">
              <RadiusIndicatorThreeMiles />
            </div>
            <div className="text-xs text-light-neutralTextWeak">
              3 Mile ({data.populationThreeMiles})
            </div>
          </div>

          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-[2px] bg-white rounded-full shadow min-w-4 aspect-square">
              <RadiusIndicatorFiveMiles />
            </div>
            <div className="text-xs text-light-neutralTextWeak">
              5 Mile ({data.populationFiveMiles})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
