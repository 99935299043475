const getCoordToFitMap = (markers: any[]): any => {
  let north = markers[0].longitude;
  let south = markers[0].longitude;
  let west = markers[0].latitude;
  let east = markers[0].latitude;

  markers.forEach(({ longitude, latitude }) => {
    if (longitude < north) {
      north = longitude;
    } else if (longitude > south) {
      south = longitude;
    }

    if (latitude < west) {
      west = latitude;
    } else if (latitude > east) {
      east = latitude;
    }
  });

  return [
    [south, west],
    [north, east],
  ];
};

export default getCoordToFitMap;
