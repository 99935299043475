import { useRef, useEffect } from 'react';
import useMapbox from '@services/mapbox/useMapbox';

import getCoordToFitMap from '@utils/getCoordToFitMap';

import YourPropertyIcon from '@assets/icons/YourProperty';
import NearestCompetitorIcon from '@assets/icons/NearestCompetitor';
import MainCompetitorIcon from '@assets/icons/MainCompetitor';

import { DataProps, NeighbourComparisonMapProps } from './NeighbourComparisonMap.types';
import { MapContainerStyled } from './NeighbourComparisonMap.styles';
import MainNeighbor from '@assets/icons/MainNeighbor';

export const NeighbourComparisonMap = ({ data }: NeighbourComparisonMapProps) => {
  const mapboxgl = useMapbox();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const zoom = 16;

  const checkCords = (items: { longitude: number; latitude: number }[]): boolean => {
    const firstItem = items[0];

    return items.every(
      (item) => item.latitude === firstItem.latitude && item.longitude === firstItem.longitude,
    );
  };

  const getMarkersArray = (data: DataProps) => {
    const markers = [{ ...data.yourProperty, type: ['yourProperty'] }];

    if (data.mainNeighbor.latitude) {
      markers.push({ ...data.mainNeighbor, type: ['mainNeighbor'] });
    }

    if (!data.mainCompetitor.name || !data.nearestCompetitor.name) {
      return markers;
    }

    if (checkCords([data.mainCompetitor, data.nearestCompetitor])) {
      markers.push({
        ...data.mainCompetitor,
        type: ['mainCompetitor', 'nearestCompetitor'],
      });
    } else {
      markers.push({ ...data.mainCompetitor, type: ['mainCompetitor'] });
      markers.push({ ...data.nearestCompetitor, type: ['nearestCompetitor'] });
    }

    return markers;
  };

  const getPlaceIconsHtml = (placeTypes: string[]): string => {
    const iconsArray = placeTypes.map((type) => `<i class="place-icon place-icon--${type}"></i>`);

    return iconsArray.join('');
  };

  useEffect(() => {
    if (map.current) return;

    const markersArray = getMarkersArray(data);

    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLDivElement,
      style: 'mapbox://styles/mapbox/streets-v12',
      zoom: zoom,
      preserveDrawingBuffer: true,
      cooperativeGestures: true,
    });

    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      'bottom-right',
    );

    map.current.on('load', () => {
      map.current.resize();

      markersArray.forEach(({ name, longitude, latitude, type }) => {
        const markerEl = document.createElement('div');
        markerEl.className = 'marker';

        const popup = new mapboxgl.Popup({
          anchor: 'top',
          closeButton: false,
          closeOnClick: false,
          closeOnMove: false,
          offset: 15,
        }).setHTML(
          `<div class=" relative flex flex-col gap-1 items-center max-w-40"><div class="flex gap-2 absolute -top-[72px]">${getPlaceIconsHtml(type)}</div><div class="text-xs text-light-neutralText">${name}</div></div>`,
        );

        new mapboxgl.Marker(markerEl)
          .setLngLat([longitude, latitude])
          .setPopup(popup)
          .addTo(map.current)
          .togglePopup();
      });

      map.current.fitBounds(getCoordToFitMap(markersArray), {
        maxZoom: 14,
        padding: { top: 48, bottom: 72, left: 72, right: 72 },
        duration: 0,
      });

      map.current.on('click', 'marker', (e: any) => {
        map.current.flyTo({
          center: e.features[0].geometry.coordinates,
        });
      });
    });

    return () => map.current.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full overflow-hidden border border-solid rounded-lg border-light-neutralBorder">
      <MapContainerStyled ref={mapContainer} className="h-80 animate-fadein"></MapContainerStyled>

      <div className="absolute px-4 py-3 bg-white border rounded left-2 bottom-2 border-light-neutralBorder">
        <div className="text-sm font-medium leading-none">Legend</div>

        <div className="flex flex-col items-start justify-start gap-2 mt-3">
          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-1 bg-white rounded shadow min-w-6 aspect-square">
              <YourPropertyIcon />
            </div>
            <div className="text-xs text-light-neutralTextWeak">Your Property</div>
          </div>

          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-1 bg-white rounded shadow min-w-6 aspect-square">
              <MainNeighbor />
            </div>
            <div className="text-xs text-light-neutralTextWeak">Main Neighbor</div>
          </div>

          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-1 bg-white rounded shadow min-w-6 aspect-square">
              <MainCompetitorIcon />
            </div>
            <div className="text-xs text-light-neutralTextWeak">Main Competitor</div>
          </div>

          <div className="inline-flex items-center justify-start gap-1">
            <div className="p-1 bg-white rounded shadow min-w-6 aspect-square">
              <NearestCompetitorIcon />
            </div>
            <div className="text-xs text-light-neutralTextWeak">Nearest Competitor</div>
          </div>
        </div>
      </div>
    </div>
  );
};
