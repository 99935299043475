const ConsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#56546D"
        d="M8.667 1.333c-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7Zm2.7 10.5-2.7-2.7-2.7 2.7-.8-.8 2.7-2.7-2.7-2.7.8-.8 2.7 2.7 2.7-2.7.8.8-2.7 2.7 2.7 2.7-.8.8Z"
      />
    </svg>
  );
};

export default ConsIcon;
