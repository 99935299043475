import { DotEmpty, DotFull } from '@assets/icons/dots';

type IndicatorProps = {
  status: 'Low' | 'Medium' | 'High' | '-';
};

const Indicator = ({ status }: IndicatorProps) => {
  const renderDots = (status: 'Low' | 'Medium' | 'High' | '-') => {
    switch (status) {
      case 'Low': {
        return (
          <>
            <DotFull />
            <DotEmpty />
            <DotEmpty />
          </>
        );
      }
      case 'Medium': {
        return (
          <>
            <DotFull />
            <DotFull />
            <DotEmpty />
          </>
        );
      }
      case 'High': {
        return (
          <>
            <DotFull />
            <DotFull />
            <DotFull />
          </>
        );
      }

      default: {
        return '-';
      }
    }
  };

  return (
    <div className="flex items-center gap-1">
      {status} {renderDots(status)}
    </div>
  );
};

export default Indicator;
