export const formatDate = (dateString: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
};
