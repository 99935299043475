import styled from 'styled-components';

export const LineChartVizWrapper = styled.g<{
  $pointerEvents: string;
}>`
  path {
    pointer-events: ${({ $pointerEvents }) => $pointerEvents};
  }
`;

export const PointLabelWrapper = styled.div`
  position: relative;
  opacity: 0.9;
  border-radius: 4px;
  box-shadow:
    0px 2px 4px 0px rgba(20, 28, 44, 0.06),
    0px 4px 8px 2px rgba(20, 28, 44, 0.06);
  background-color: ${({ theme }) => theme['neutralBackgroundExtraDark']};
  padding: 2px 4px;
  width: fit-content;
  height: auto;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme['neutralTextInverted']};
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  translate: -50% calc(-100% - 7px);
`;
