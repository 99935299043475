import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Outlet } from 'react-router';

import NotFound from '@pages/not-found/NotFound';
import Reports from '@pages/reports/Reports';
import Clients from '@pages/clients/Clients';
import Report from '@pages/report/Report';
import { AuthenticatedGuardedRoute } from '../Guards/AuthenticatedGuardedRoute';
import { AuthenticatedLayout } from '../../layout/AuthenticatedLayout';
import { useKeycloak } from '@react-keycloak/web';

const AppRouter: FC = () => {
  const { keycloak } = useKeycloak();

  const authenticate = () => {
    keycloak.login();
  };

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    keycloak.onReady = function (authenticated) {
      if (!authenticated) {
        authenticate();
      }
    };
  }, [keycloak?.authenticated]);

  const authenticatedRouter = (
    <Route
      path="dashboard"
      element={
        <AuthenticatedGuardedRoute>
          <AuthenticatedLayout>
            <Outlet />
          </AuthenticatedLayout>
        </AuthenticatedGuardedRoute>
      }
    ></Route>
  );

  return (
    <BrowserRouter>
      <Routes>
        {authenticatedRouter}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Clients />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/clients/:id" element={<Reports />} />
        <Route path="/reports/:reportId" element={<Report />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
