import { BadgeStatus } from '@constants/badge';

export const getBadgeStatus = (status: BadgeStatus) => {
  switch (status) {
    case BadgeStatus.NEGATIVE:
    case BadgeStatus.VERY_NEGATIVE:
      return 'error';
    case BadgeStatus.NEUTRAL:
      return 'default';
    case BadgeStatus.POSITIVE:
      return 'info';
    case BadgeStatus.VERY_POSITIVE:
      return 'success';
    default:
      return 'default';
  }
};
