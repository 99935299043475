import { Themes } from '../../../constants/';

export type ColorPalette = keyof typeof Themes.light.dataViz;

export interface DataItem {
  [key: string]: string | number;
}

export interface DataKeys {
  x: string;
  y: string[];
}

export interface DonutChartProps {
  data: DataItem[];
  colorPalette?: ColorPalette;
  dataKeys: DataKeys;
  hasColorsOrderedByIndex?: boolean;
  hasLabels?: boolean;
  height?: number;
  isOrderedByIndex?: boolean;
  thicknessPercent?: number;
  width?: number;
  withTooltip?: boolean;
}

export interface TooltipData {
  data: {
    key: string | number;
    index: string;
    value: string | number;
  };
  color?: string;
}

export const defaultChartParams = {
  width: 400,
  height: 400,

  thicknessPercent: 20,

  tooltip: {
    width: 'fit-content',
    minWidth: 40,
    maxWidth: 80,
    leaveTimeout: 300,
  },
};
