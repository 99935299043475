import { ReactNode } from 'react';

import { Card } from '@components/Card';
import PercentageBar from '@components/PercentageBar';

type ComparisonCardProps = {
  icon: ReactNode;
  title: string;
  address: string;
  barLabel1: string;
  barLabel2: string;
  percentage1: number;
  percentage2: number;
  primary?: 'primary' | 'secondary';
  accentColor?: string;
};

const ComparisonCard = ({
  icon,
  title,
  address,
  barLabel1,
  barLabel2,
  percentage1,
  percentage2,
  primary = 'primary',
  accentColor,
}: ComparisonCardProps) => {
  const background =
    primary === 'primary' ? 'bg-light-neutralBackgroundBase' : 'bg-light-neutralBackground';
  const primaryShadow = primary === 'primary' && 'drop-shadow-card';

  return (
    <Card className={`flex flex-col gap-4 ${background} ${primaryShadow}`}>
      <div className="flex flex-col gap-1">
        <div className="flex gap-2">
          <div className="w-6 p-1 rounded shadow bg-light-neutralBackgroundBase">{icon}</div>
          <div className="font-normal text-xl leading-6 tracking-[-2%] text-light-neutralText items-center">
            {title}
          </div>
        </div>

        <div className="text-xs leading-[14px] font-normal text-light-neutralTextWeak h-[2lh]">
          {address}
        </div>
      </div>

      <div className="flex flex-col gap-[14px]">
        <div className="uppercase tracking-[8%] text-[10px] leading-3 font-normal text-light-neutralTextWeak">
          cfs score percentile
        </div>

        <PercentageBar accentColor={accentColor} percentage={percentage1} label={barLabel1} />

        <PercentageBar accentColor={accentColor} percentage={percentage2} label={barLabel2} />
      </div>
    </Card>
  );
};

export default ComparisonCard;
