import { Card } from '@components/Card/Card';
import { AreaChart } from '@components/Charts/AreaChart';

import { ProbabilityClosureCardProps } from './ProbabilityClosureCard.types';

const valueFormatter = (value: number): string =>
  `${Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value)}%`;

export const ProbabilityClosureCard = ({ data }: ProbabilityClosureCardProps) => {
  const chartData = Object.keys(data).map((key: string) => ({
    horizon: key.replace('Prediction ', ''),
    probability: data[key] as number,
  }));

  return (
    <Card className="flex flex-col w-full gap-4">
      <h3 className="text-xl leading-none tracking-tight">Probability of Closure</h3>

      <div className="animate-fadein [animation-duration:0.4s]">
        <AreaChart
          data={chartData}
          dataKeys={{
            x: 'horizon',
            y: ['probability'],
          }}
          height={200}
          bottomAxisLabel={'Future Horizon'}
          colorPalette={'qualitative_2_2'}
          leftAxisTickFormatter={valueFormatter}
          maxYScaleValueOffsetFactor={1.5}
          niceScale={true}
          pointLabelFormatter={valueFormatter}
          roundScale={true}
          showBottomAxis={true}
          showLeftAxis={true}
          showVerticalAxesTicks={true}
          withPointsLabels={true}
          yAxisNumTicks={4}
        />
      </div>
    </Card>
  );
};
