const milesToMapPixels = (latitude: number, miles: number, zoomLevel: number) => {
  const mileInMeters = 1609.344;
  const metersPerPixelAtZeroZoomLevel = 78271.484;
  const devicePixelRatio = window.devicePixelRatio;

  const mapPixels =
    (miles * mileInMeters) /
    (metersPerPixelAtZeroZoomLevel / 2 ** zoomLevel) /
    Math.cos((latitude * Math.PI) / 180);

  const screenPixel = mapPixels * Math.floor(devicePixelRatio);

  return screenPixel;
};

export default milesToMapPixels;
