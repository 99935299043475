import { useMemo } from 'react';

import { Card } from '@components/Card/Card';
import { DonutChart } from '@components/Charts/DonutChart';
import { Legend } from '@components/Legend';

import { ProbabilityOpeningCardProps } from './ProbabilityOpeningCard.types';
import { ScoreWrapperStyled } from './ProbabilityOpeningCard.styles';

const defaultBaseScore = 72;

export const ProbabilityOpeningCard = ({
  cfsScore = 0,
  baseScore = defaultBaseScore,
}: ProbabilityOpeningCardProps) => {
  const diffScore = useMemo(() => cfsScore - baseScore, [cfsScore, baseScore]);
  const diffScoreAbsoluteValue = useMemo(() => Math.abs(diffScore), [diffScore]);
  const rest = useMemo(
    () => 100 - (cfsScore > baseScore ? cfsScore : baseScore),
    [baseScore, cfsScore],
  );
  const diffScoreMessage = useMemo(
    () => (diffScore < 0 ? 'less than baseline' : 'more than baseline'),
    [diffScore],
  );

  const colorPalette = diffScore < 0 ? 'qualitative_3_cfs_score_2' : 'qualitative_3_cfs_score';

  const chartData = useMemo(
    () => [
      {
        key: 'Chain Baseline',
        '%': 100 - rest - diffScoreAbsoluteValue,
      },
      {
        key: diffScoreMessage,
        '%': diffScoreAbsoluteValue,
      },
      {
        key: 'Rest',
        '%': rest,
      },
    ],
    [diffScoreAbsoluteValue, diffScoreMessage, rest],
  );

  return (
    <Card>
      <div className="relative flex items-stretch w-full h-full gap-4">
        <div className="flex gap-4 shrink">
          <div className="flex flex-col justify-between gap-4">
            <h3 className="text-xl leading-6 tracking-tight text-balance">
              Probability of being open in 5 years
            </h3>
          </div>

          <div className="flex items-center justify-center min-h-[200px] animate-fadein [animation-duration:0.4s]">
            <DonutChart
              data={chartData}
              dataKeys={{ x: 'key', y: ['%'] }}
              height={200}
              colorPalette={colorPalette}
              hasColorsOrderedByIndex={true}
              isOrderedByIndex={true}
            >
              <ScoreWrapperStyled
                className="flex items-center justify-center p-5 rounded-full aspect-square bg-[#012023]"
                $score={cfsScore}
                $baseScore={baseScore}
              >
                <div className="text-5xl font-semibold text-white">
                  {cfsScore}
                  <span className="pb-1 text-sm font-normal">%</span>
                </div>
              </ScoreWrapperStyled>
            </DonutChart>
          </div>
        </div>

        <div className="absolute bottom-0 w-full">
          <Legend
            labels={[
              `<span class="text-light-neutralText font-semibold">${baseScore}%</span> Chain Baseline`,
              `<span class="text-light-neutralText font-semibold">${diffScoreAbsoluteValue}%</span> ${diffScoreMessage}`,
            ]}
            colorPalette={colorPalette}
            direction="vertical"
            height="auto"
          />
        </div>
      </div>
    </Card>
  );
};
