const SortArrows = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 17.5L5.625 13.125L6.50625 12.2437L10 15.7312L13.4938 12.2437L14.375 13.125L10 17.5Z"
        fill="#02484F"
      />
      <path
        d="M10 2.5L14.375 6.875L13.4938 7.75625L10 4.26875L6.50625 7.75625L5.625 6.875L10 2.5Z"
        fill="#02484F"
      />
    </svg>
  );
};

export default SortArrows;
