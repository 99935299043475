import { styled } from 'styled-components';

export const MapContainerStyled = styled.div`
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none;
  }

  .mapboxgl-ctrl-group {
    border-radius: 16px;
    overflow: hidden;
  }

  .marker {
    background-image: url(/assets/icons/your-property-icon.svg);
    background-size: cover;
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 4px 8px rgba(20, 28, 44, 0.15));
  }

  .place-icon {
    background-size: cover;
    display: inline-block;
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 2px 4px rgba(20, 28, 44, 0.15));
  }

  .mapboxgl-popup-content {
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 140px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    filter: drop-shadow(0px 0px 24px rgba(17, 17, 17, 0.08));
  }
`;
