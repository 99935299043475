import { AvatarWrapper, Image, Name } from './styles';
import { getInitials } from '@utils/userInitials';
import { AvatarProps } from './types';

const Avatar = ({
  size = 'md',
  disabled,
  picture,
  pictureAlt,
  userName,
  className,
  empty,
  onClick,
}: AvatarProps) => {
  return (
    <AvatarWrapper
      className={className}
      onClick={onClick}
      $size={size}
      $disabled={disabled}
      $empty={empty}
    >
      {picture ? (
        <Image $size={size} $empty={empty} src={picture} alt={pictureAlt} />
      ) : (
        <Name $size={size} $empty={empty}>
          {getInitials(userName)}
        </Name>
      )}
    </AvatarWrapper>
  );
};

export default Avatar;
