import styled from 'styled-components';

export const ScattterPlotWrapperStyled = styled.div`
  .visx-rows {
    display: none;
  }
`;

export const PointLabelWrapper = styled.div<{
  $x: number;
  $y: number;
  $gridWidth: number;
  $gridHeight: number;
}>`
  position: relative;
  top: ${({ $y }) => ($y ? `${55 - $y + 16}px` : '')};
  left: ${({ $x, $gridWidth }) => {
    if ($x < 50) {
      return `${35 - $x}px`;
    }
    if ($x > $gridWidth - 50) {
      return `-${35 - ($gridWidth - $x)}px`;
    }
  }};
  opacity: 0.9;
  border-radius: 4px;
  box-shadow:
    0px 2px 4px 0px rgba(20, 28, 44, 0.06),
    0px 4px 8px 2px rgba(20, 28, 44, 0.06);
  background-color: ${({ theme }) => theme['neutralBackgroundExtraDark']};
  padding: 2px 4px;
  width: 100px;
  text-align: center;
  height: auto;
  line-height: 1em;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme['neutralTextInvertedWeak']};
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  translate: -50px 100%;
`;
