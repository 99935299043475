export const UploadIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#99D8DF" />
      <path
        d="M24.8752 29.2503L25.7564 30.1316L27.3752 28.5191V36.1253H28.6252V28.5191L30.2439 30.1316L31.1252 29.2503L28.0002 26.1253L24.8752 29.2503Z"
        fill="#0E717B"
      />
      <path
        d="M32.6877 31.7503H32.3752V30.5003H32.6877C33.4336 30.5302 34.1608 30.2625 34.7093 29.7561C35.2579 29.2498 35.5828 28.5463 35.6127 27.8003C35.6425 27.0544 35.3748 26.3272 34.8684 25.7787C34.3621 25.2301 33.6586 24.9052 32.9127 24.8753H32.3752L32.3127 24.3628C32.174 23.3106 31.6576 22.3447 30.8596 21.645C30.0616 20.9452 29.0365 20.5594 27.9752 20.5594C26.9138 20.5594 25.8887 20.9452 25.0907 21.645C24.2927 22.3447 23.7763 23.3106 23.6377 24.3628L23.6252 24.8753H23.0877C22.3417 24.9052 21.6382 25.2301 21.1319 25.7787C20.6255 26.3272 20.3578 27.0544 20.3877 27.8003C20.4175 28.5463 20.7424 29.2498 21.291 29.7561C21.8395 30.2625 22.5667 30.5302 23.3127 30.5003H23.6252V31.7503H23.3127C22.3104 31.744 21.3459 31.3674 20.6045 30.6929C19.8632 30.0184 19.3973 29.0937 19.2965 28.0965C19.1957 27.0993 19.4671 26.1001 20.0584 25.2909C20.6498 24.4817 21.5195 23.9196 22.5002 23.7128C22.77 22.4544 23.4632 21.3266 24.4641 20.5175C25.465 19.7084 26.7131 19.2671 28.0002 19.2671C29.2872 19.2671 30.5353 19.7084 31.5362 20.5175C32.5371 21.3266 33.2303 22.4544 33.5002 23.7128C34.4809 23.9196 35.3505 24.4817 35.9419 25.2909C36.5333 26.1001 36.8046 27.0993 36.7038 28.0965C36.603 29.0937 36.1372 30.0184 35.3958 30.6929C34.6544 31.3674 33.6899 31.744 32.6877 31.7503Z"
        fill="#0E717B"
      />
    </svg>
  );
};
