import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';

import Button from '@components/Button';
import Avatar from '@components/Avatar';

import Logo from '@assets/Logo';
import Download from '@assets/icons/Download';
import Cheveron from '@assets/icons/Cheveron';
import LogOut from '@assets/icons/LogOut';

interface HeaderProps {
  className?: string;
  onDownloadReport?: () => void;
  backButtonHandler?: () => void;
}

const Header = ({ className, onDownloadReport, backButtonHandler = () => {} }: HeaderProps) => {
  const { keycloak } = useKeycloak();
  const { reportId } = useParams();

  const handleDownloadReport = onDownloadReport;

  const baseClassName =
    'flex items-center justify-between h-16 px-5 border-b border-solid gap-x-4 border-y-0 border-x-0 border-light-neutralBorder';

  const componentClassName = [baseClassName, className].filter(Boolean).join(' ');

  const handleLogOut = useCallback(() => keycloak.logout(), [keycloak]);

  return (
    <header className={componentClassName}>
      <a href="/">
        <Logo />
      </a>

      <div className="flex items-center self-stretch justify-between flex-1 px-4 border-solid border-x border-y-0 border-light-neutralBorder">
        {reportId && (
          <>
            <Button variant="ghost" iconLeft={<Cheveron />} onClick={backButtonHandler}>
              Back to Reports
            </Button>

            <div className="flex gap-2">
              <Button size="md" icon={<Download />} onClick={handleDownloadReport}>
                Download
              </Button>
            </div>
          </>
        )}
      </div>

      <Avatar className="!self-center" userName={'John Doe'} pictureAlt="John Doe" />

      <Button variant="ghost" icon={<LogOut />} onClick={handleLogOut}>
        Log out
      </Button>
    </header>
  );
};

export default Header;
