import { ReactNode } from 'react';

interface SectionTitleProps {
  title: string;
  icon?: ReactNode;
  className?: string;
}

export const SectionTitle = ({ title, icon, className }: SectionTitleProps) => {
  return (
    <div
      className={`inline-flex items-center justify-start gap-2 font-semibold ${className ? className : ''}`}
    >
      {icon}
      <h2 className="text-2xl leading-none tracking-tight">{title}</h2>
    </div>
  );
};
