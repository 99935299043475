import { useEffect, useState } from 'react';

import CompareIcon from '@assets/icons/Compare';
import ConsIcon from '@assets/icons/Cons';
import EventsIcon from '@assets/icons/Events';
import MapIcon from '@assets/icons/Map';
import MaximizeIcon from '@assets/icons/Maximize';
import ProsIcon from '@assets/icons/Pros';
import { Card } from '@components/Card/';

import { FactorsCardProps } from './FactorsCard.types';

export const FactorsCard = ({ data }: FactorsCardProps) => {
  const [factorsMap, setFactorsMap] = useState<any>();
  const [cons, setCons] = useState<string[]>();
  const [pros, setPros] = useState<string[]>();

  useEffect(() => {
    const factorsMapFormatted = {
      'Factor 1': {
        name: data['Chain Top 1'],
        icon: <MaximizeIcon />,
      },
      'Factor 2': {
        name: data['Chain Top 2'],
        icon: <EventsIcon />,
      },
      'Factor 3': {
        name: data['Chain Top 3'],
        icon: <MapIcon />,
      },
      'Factor 4': {
        name: data['Chain Top 4'],
        icon: <CompareIcon />,
      },
    };

    setFactorsMap(factorsMapFormatted);

    const prosItems = [] as string[];
    const consItems = [] as string[];

    ['Factor 1', 'Factor 2', 'Factor 3', 'Factor 4'].forEach((key) => {
      if (data[key] === 'PRO') {
        prosItems.push(key);
      } else if (data[key] === 'CON') {
        consItems.push(key);
      }
    });

    setCons(consItems);
    setPros(prosItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card className="flex flex-col w-full gap-4">
      <h3 className="text-xl leading-none tracking-tight">Factors</h3>

      {!!pros?.length && (
        <div>
          <>
            <h4 className="text-sm leading-none">Pros</h4>
            <div className="flex flex-col gap-2 px-3 py-1 mt-2 border border-solid rounded-lg border-light-neutralBorder">
              {pros.map((proItem) => {
                const item = factorsMap[proItem as keyof typeof factorsMap];

                return (
                  <div key={`${proItem}`} className="flex items-center gap-1 text-pretty">
                    <div className="grow">{item.name}</div>
                    <div className="shrink-0">
                      <ProsIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      )}

      {!!cons?.length && (
        <div>
          <h4 className="text-sm leading-none">Cons</h4>
          <div className="flex flex-col gap-2 px-3 py-1 mt-2 border border-solid rounded-lg border-light-neutralBorder">
            {cons.map((consItem) => {
              const item = factorsMap[consItem as keyof typeof factorsMap];
              return (
                <div key={`${consItem}`} className="flex items-center gap-1 text-pretty">
                  <div className="grow">{item.name}</div>
                  <div className="shrink-0">
                    <ConsIcon />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Card>
  );
};
