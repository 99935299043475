export const SubItemArrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2929 7.20949L12.0859 9.00244H3V4.00244H2V9.00244C2.00032 9.26756 2.10578 9.52173 2.29324 9.7092C2.48071 9.89666 2.73488 10.0021 3 10.0024H12.0859L10.293 11.7954L11 12.5024L14 9.50244L11 6.50244L10.2929 7.20949Z"
        fill="#939BA7"
      />
    </svg>
  );
};
