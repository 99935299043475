const FeaturesIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 3.125C1.25 2.08947 2.08947 1.25 3.125 1.25L6.875 1.25C7.91053 1.25 8.75 2.08947 8.75 3.125V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H3.125C2.08947 8.75 1.25 7.91053 1.25 6.875L1.25 3.125ZM11.25 3.125C11.25 2.08947 12.0895 1.25 13.125 1.25L16.875 1.25C17.9105 1.25 18.75 2.08947 18.75 3.125V6.875C18.75 7.91053 17.9105 8.75 16.875 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V3.125ZM1.25 13.125C1.25 12.0895 2.08947 11.25 3.125 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V16.875C8.75 17.9105 7.91053 18.75 6.875 18.75H3.125C2.08947 18.75 1.25 17.9105 1.25 16.875L1.25 13.125ZM11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H16.875C17.9105 11.25 18.75 12.0895 18.75 13.125V16.875C18.75 17.9105 17.9105 18.75 16.875 18.75H13.125C12.0895 18.75 11.25 17.9105 11.25 16.875V13.125Z"
        fill="url(#paint0_linear_777_38329)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_38329"
          x1="8.71324"
          y1="1.25"
          x2="8.71324"
          y2="18.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D5EA" />
          <stop offset="1" stopColor="#FFA26E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FeaturesIcon;
