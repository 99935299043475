const LogOut = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="#02484F"
        d="M3.75 18.75h7.5a1.252 1.252 0 0 0 1.25-1.25v-1.875h-1.25V17.5h-7.5v-15h7.5v1.875h1.25V2.5a1.252 1.252 0 0 0-1.25-1.25h-7.5A1.251 1.251 0 0 0 2.5 2.5v15a1.252 1.252 0 0 0 1.25 1.25Z"
      />
      <path
        fill="#02484F"
        d="m12.866 12.866 2.242-2.241H6.25v-1.25h8.857l-2.24-2.241.883-.884L17.5 10l-3.75 3.75-.884-.884Z"
      />
    </svg>
  );
};

export default LogOut;
