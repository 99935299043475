import { Form, Formik } from 'formik';
import { Step1 } from '../steps/Step1';
import { Step2 } from '../steps/Step2';
import { Step3 } from '../steps/Step3';
import { useEffect, useState } from 'react';
import { SuccessModalIcon } from '@assets/icons/SuccessModalIcon';
import { ModalIcon } from '@assets/icons/ModalIcon';

interface Props {
  setModalProps: React.Dispatch<
    React.SetStateAction<{
      title: string;
      icon: () => JSX.Element;
    }>
  >;
  isReportsPage?: boolean;
  clientId?: string;
}

const formInitialValues = {
  existing: '',
  name: '',
  email: '',
  company: '',
  industry: '',
  brandProprety: '',
  address: '',
  files: '',
};

export const CreateReportModal = (props: Props) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const { setModalProps, clientId, isReportsPage } = props;

  const handleSubmit = () => {};

  const handleNextStep = () => {
    return setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setModalProps({ title: 'Create Report', icon: ModalIcon });
    return setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (activeStep === 3) {
      setModalProps({ title: 'Your Report was generated successfully!', icon: SuccessModalIcon });
      return;
    } else if (activeStep < 3) {
      setModalProps({ title: 'Create Report', icon: ModalIcon });
    }
  }, [activeStep]);

  useEffect(() => {
    if (clientId && isReportsPage) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, [clientId, isReportsPage]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => {
        return (
          <Form onSubmit={handleSubmit} className="flex flex-col gap-6">
            {activeStep === 1 && (
              <Step1
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleNextStep={handleNextStep}
                setValues={setValues}
              />
            )}
            {activeStep === 2 && (
              <Step2
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                setValues={setValues}
                clientId={clientId}
              />
            )}
            {activeStep === 3 && <Step3 values={values} />}
          </Form>
        );
      }}
    </Formik>
  );
};
