import styled from 'styled-components';

export const ChartWrapper = styled.div<{
  $maxWidth: number;
  $maxHeight: number;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  line-height: 0;
  aspect-ratio: 1;
  z-index: 1;
`;

export const ChildrenWrapper = styled.div<{
  $radius: number;
  $top: number;
  $left: number;
  $color?: string;
}>`
  color: ${({ $color }) => $color};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${({ $radius }) => `${$radius * 2}px`};
  height: ${({ $radius }) => `${$radius * 2}px`};
  border-radius: 50%;
  margin-top: ${({ $top }) => `${$top}px`};
  margin-left: ${({ $left }) => `${$left}px`};
  z-index: -1;
`;
