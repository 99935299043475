const ProsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#36C4A5"
        d="M8.667 1.333a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-1 9.796-2.5-2.5.795-.796 1.705 1.705 3.705-3.705.798.793-4.503 4.503Z"
      />
    </svg>
  );
};

export default ProsIcon;
