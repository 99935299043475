import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Card } from '@components/Card';
import Button from '@components/Button';
import Table from '@components/Table';
import Header from '@views/Header';

import { AxiosController } from '@utils/axiosController';
import { columns, processedData } from './data/data';
import { UserData } from './types/types';

import ReportIcon from '@assets/icons/svgIcons/reports.svg';
import CreateClientIcon from '@assets/icons/CreateClient';
import SortArrows from '@assets/icons/SortArrows';
import { Modal } from '@components/Modal';
import ReturnIcon from '@assets/icons/ReturnIcon';
import Loader from '@components/Loader';
import {useKeycloak} from "@react-keycloak/web";

const Reports = () => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const params = useParams();
  const [data, setData] = useState<UserData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [client, setClient] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [sortColumn, setSortColumn] = useState<keyof UserData | undefined>(undefined);
  const { get, getById } = AxiosController(setLoading);
  const [columnDirection, setColumnDirection] = useState<'ASC' | 'DESC'>('DESC');
  const reportsPerPage = 1000;

  const navigate = useNavigate();

  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      try {
        const [client, reports] = await Promise.all([
          getById('/clients', params.id),
          get(`/reports?limit=${reportsPerPage}`),
        ]);

        setClient(client?.item.fullName);
        setData(reports?.items.filter((item: any) => item.clientId === params.id) ?? []);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <Header />
      <div className="bg-[#D3DBE3] p-[20px] flex flex-col min-h-[100vh]">
        <div className=" bg-white p-[24px] flex flex-col gap-[42px] rounded-2xl">
          <div className="flex items-center justify-between">
            <div className="text-[12px] flex gap-1 items-center">
              <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={() => navigate('/clients')}
              >
                <ReturnIcon />
                <p className="bg-[#5F687714] text-[#5F6877] py-[1px] px-1 rounded-2xl">Clients</p>
              </div>
              <span className="text-[#939BA7]">/</span>
              <div className={`text-[#0B101A] ${loading ? 'ml-3' : ''}`}>
                {loading ? <Loader loaderType="component" size="sm" /> : client}
              </div>
            </div>
            <div className="flex gap-3">
              <Button
                variant="ghost"
                onClick={() => setSortColumn('createdAt')}
                icon={<SortArrows />}
              >
                {columnDirection === 'ASC' ? 'First ' : 'Last '} Created
              </Button>
              <Button onClick={handleOpenModal} variant="secondary" icon={<CreateClientIcon />}>
                Create report
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-[#0B101A] text-[24px] font-bold flex gap-2 items-center">
              <img src={ReportIcon} className="w-5 h-5" alt="clients-icon" />
              Reports
            </div>

            <Card>
              <Table
                data={processedData(data, (url: string) => navigate(url))}
                columns={columns}
                initialSortField="createdAt"
                initialSortDirection="DESC"
                loading={loading}
                sortColumn={{
                  sortColumnKey: sortColumn,
                  sortColumHandler: (dir: 'ASC' | 'DESC') => {
                    setSortColumn(undefined);
                    setColumnDirection(dir);
                  },
                }}
              />
            </Card>
          </div>
        </div>
      </div>
      <Modal
        modalOpen={open}
        closeModal={handleCloseModal}
        clientId={params.id}
        isReportsPage={true}
      />
    </div>
  );
};

export default Reports;
