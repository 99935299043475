const RadiusIndicatorThreeMiles = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
      <rect width="12" height="12" fill="url(#a)" fillOpacity=".7" rx="6" />
      <defs>
        <radialGradient
          id="a"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 6 -6 0 6 6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47B0FF" stopOpacity="0" />
          <stop offset=".783" stopColor="#47B0FF" stopOpacity=".6" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default RadiusIndicatorThreeMiles;
