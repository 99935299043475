import { useState } from 'react';

interface Props {
  id: string;
  handleChange: (e: any) => void;
  values?: string;
  label?: string;
  placeholder?: string;
  errors?: string;
  touched?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const Input = (props: Props) => {
  const { id, values, handleChange, label, disabled } = props;

  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div
      className={`${focus ? 'border-light-greenInputActive' : 'border-ui-gray4 '} transition-all duration-300 ease-in-out relative bg-light-neutralBackgroundBase text-sm text-ui-gray11 border p-3 rounded`}
    >
      {!!label && focus && (
        <label
          className={`${focus ? 'left-3 top-1 text-light-greenInputActive' : 'hidden'} text-xs absolute z-10`}
        >
          {label}
        </label>
      )}
      <input
        className={`${focus ? 'relative -bottom-2' : 'z-10'} bg-light-neutralBackgroundBase outline-none placeholder-light-primaryGrayButton-default text-ui-gray11`}
        id={id}
        disabled={disabled}
        placeholder={!focus ? label : ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type="text"
        value={values}
        onChange={handleChange}
      />
    </div>
  );
};
