import React, { ReactElement, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';

interface GuardProps {
  children: ReactElement<any, any>;
}

export const AuthenticatedGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak) {
      return;
    }
  }, [keycloak]);
  return <>{children}</>;
};
