import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Card } from '@components/Card';
import Button from '@components/Button';
import Table from '@components/Table';
import Header from '@views/Header';
import { Modal } from '@components/Modal';

import { AxiosController } from '@utils/axiosController';
import { columns, processedData } from './data/data';
import { UserData } from './types/types';

import ClientIcon from '@assets/icons/svgIcons/clients.svg';
import CreateClientIcon from '@assets/icons/CreateClient';
import SortArrows from '@assets/icons/SortArrows';
import {useKeycloak} from "@react-keycloak/web";

const Clients = () => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const [data, setData] = useState<UserData[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortColumn, setSortColumn] = useState<keyof UserData | undefined>(undefined);
  const { get } = AxiosController(setLoading);
  const [columnDirection, setColumnDirection] = useState<'ASC' | 'DESC'>('DESC');


  const navigate = useNavigate();

  useEffect(() => {
    if(!token){
      return
    }
    const fetchData = async () => {
      const result = await get('/clients');

      setData(result?.items);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <Header />
      <div className="bg-[#D3DBE3] p-[20px] flex flex-col min-h-[100vh]">
        <div className=" bg-white p-[24px] flex flex-col gap-[42px] rounded-2xl">
          <div className="flex justify-between">
            <div className="text-[#0B101A] text-[24px] font-bold flex gap-2 items-center">
              <img src={ClientIcon} className="w-5 h-5" alt="clients-icon" />
              Clients
            </div>
            <div className="flex gap-3">
              <Button
                variant="ghost"
                icon={<SortArrows />}
                onClick={() => setSortColumn('createdAt')}
              >
                {columnDirection === 'ASC' ? 'First ' : 'Last '} Created
              </Button>
              <Button onClick={handleOpenModal} variant="secondary" icon={<CreateClientIcon />}>
                Create report
              </Button>
            </div>
          </div>

          <Card>
            <Table
              data={processedData(data, (url: string) => navigate(url))}
              columns={columns}
              initialSortField="createdAt"
              initialSortDirection="DESC"
              loading={loading}
              sortColumn={{
                sortColumnKey: sortColumn,
                sortColumHandler: (dir: 'ASC' | 'DESC') => {
                  setSortColumn(undefined);
                  setColumnDirection(dir);
                },
              }}
            />
          </Card>
        </div>
      </div>
      <Modal modalOpen={open} closeModal={handleCloseModal} />
    </div>
  );
};

export default Clients;
