import React, { PropsWithChildren, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const AuthenticatedLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();

  const userInfo = useMemo(() => keycloak.tokenParsed, [keycloak]);

  const navbar = (
    <nav className="bg-white border-b border-gray-200 fixed top-0 inset-x-0 z-10 h-14 flex items-center justify-between px-4">
      <span className="text-lg font-semibold">{userInfo?.name}</span>
      <div className="absolute top-2 right-4">
        <button
          className="text-blue-500 hover:text-blue-600 border p-2 border-blue-600 rounded"
          onClick={() => keycloak.logout()}
        >
          Log out
        </button>
      </div>
    </nav>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {navbar}
      <div className="pt-[56px] flex-grow max-h-full">{children}</div>
    </div>
  );
};
